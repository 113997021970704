import { Card, Typography } from "@material-tailwind/react";
import { useEffect } from "react";
import {useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { faDownload, faPlay, faInfoCircle, faTrash ,faMars, faVenus, faVenusMars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import {setAISettings} from '../../../reducers/cartReducer';

 const MediaTable = ({headers = [], rows = [], onSelected=undefined, onInfoSelected=undefined, onDelete = undefined}) => {
    const [renderRows, setRenderRows] = useState([]);
    const dispatch = useDispatch();
    const  state = useSelector((state) => state);
    useEffect(() => {
        setRenderRows(rows);
    }, [rows]);


    const GetFilename = (url) =>
{
   if (url)
   {
      var m = url.toString().match(/.*\/(.+?)\./);
      if (m && m.length > 1)
      {
         return m[1];
      }
   }
   return "";
}
    const downloadFile = (url) => {
        console.log(url);
        fetch(url).then(function(t) {
            return t.blob().then((b)=>{
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", GetFilename(url));
                a.click();
            }
            );
        });
    }
    return (
<Card className="max-h-[56vh] h-[56vh] w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {headers.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderRows.map( (item, index) => {
            const isLast = index === rows.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
            return (
              <tr key={item.id}>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {(index+1).toString()}
                  </Typography>
                </td>
                <td className={classes}>
                    <div className=" text-xs ">
                  <FontAwesomeIcon icon={faPlay} className=" text-xs mr-3 text-blue-800 cursor-pointer" onClick={()=>{ onSelected(item); }}/>
                  <FontAwesomeIcon icon={faInfoCircle}className=" text-xs mr-3 text-blue-800 cursor-pointer"  onClick={()=>{ onSelected(item);}}/>
                  <a href={item.media} download="file" target='_blank'>
                  <FontAwesomeIcon icon={faDownload} className=" text-xs mr-3 text-blue-800 cursor-pointer" />
                  </a>
                  <FontAwesomeIcon icon={faTrash} className=" text-xs mr-3 text-red-900 cursor-pointer" onClick={()=>{ onDelete(item);}}/>
                  </div>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs"
                  >
                    {item.prompt ?item.prompt.substring(0, 10) + "..." : ""}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs"
                  >
                    {item.model}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs"
                  >
                    {item.gender ? item.gender == "Male" ?   <FontAwesomeIcon icon={faMars} className=" text-xs mr-3 text-blue-800 cursor-pointer" /> : item.gender == "Custom" ? <FontAwesomeIcon icon={faVenusMars} className=" text-xs mr-3 text-green-400 cursor-pointer" /> : <FontAwesomeIcon icon={faVenus} className=" text-xs mr-3 text-pink-400 cursor-pointer" /> : <></> } {item.speaker ? item.speaker : "None"}
                  </Typography>

                </td>
{/*                   
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs"
                  >
                    {moment(item.date).format('hh:mm:ss')}
                  </Typography>
                </td> */}

                
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>

    )
}

export default MediaTable;