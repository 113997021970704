import * as React from 'react';
import {isMobile} from 'react-device-detect';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function CameraDevices({onCameraSelected}) {
  const [isMobile_, setisMobile_] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState('');
  const [deviceId, setDeviceId] = React.useState('');
  const [devices, setDevices] = React.useState([]);
  const [devicesHtml, setDevicesHtml] = React.useState(null);

  
  const handleDevices = React.useCallback(
    mediaDevices =>{
        const items = mediaDevices.filter(({ kind }) => kind === "videoinput");
      setDevices(items);
      setDevicesHtml(items.map((device) => {
        return (<MenuItem value={device.deviceId}>{device.label}</MenuItem>);
    }));
    const ite = localStorage.getItem("deviceId", "");
    if(ite != ""){
        setDeviceId(ite);
        onCameraSelected(ite);
    }
    },
    [setDevices]
  );

  React.useEffect(
    () => {
        setDeviceId(localStorage.getItem("deviceId", ""));
        if(isMobile){
            setisMobile_(true);
            const items = [{deviceId:"environment",label:"Environment"},{deviceId:"user",label:"Selfie"}]
      setDevicesHtml(items.map((device) => {
        return (<MenuItem value={device.deviceId}>{device.label}</MenuItem>);
    }));
    const ite = localStorage.getItem("deviceId", "");
    if(ite != ""){
        setDeviceId(ite);
        onCameraSelected(ite);
    }else{
        setDeviceId("environment");
        onCameraSelected("environment");
        setisMobile_(true);
    }
            return;
              
        }else{
            setisMobile_(false);
        }
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices]
  );

  const handleChange = (event) => {
    setDeviceId(event.target.value);
    onCameraSelected(event.target.value);
  };

  const handleClickOpen = () => {
    console.log(devices);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <div>
        <button className="shutterButton" onClick={handleClickOpen}><img className=" shutterButton-img" src="images/Icons/icon-park-solid_flip-camera.png"/></button>
      {/* <Button onClick={handleClickOpen}>Open select dialog</Button> */}
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle> Select Your Camera</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-dialog-select-label">Cameras</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={deviceId}
                onChange={handleChange}
                input={<OutlinedInput label="Cameras" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {devicesHtml}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}