export const xttsVoices = {
    model: "XTTS",
    speakers: [
        {
            Number: "2",
            Name: "Camille J. - Service",
            Gender: "Female",
            Accent: "American",
            Age: "Young",
            Traits: "Young",
            URL:[
                "https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/CamilleVoice_Commercial_Reg.txt",
                "https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/CamilleVoice_SCP_A.txt",
                "https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/CamilleVoice_SCP_B.txt",
        
        ]
        },
        {
            Number: "2",
            Name: "Camille J. - Country",
            Gender: "Female",
            Accent: "American",
            Age: "Young",
            Traits: "Country",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/CamilleVoice_Commercial_Country.txt"]
        },
        {
            Number: "2",
            Name: "Camille J. - Monotone",
            Gender: "Female",
            Accent: "American",
            Age: "Young",
            Traits: "Monotone",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/CamilleVoice_SCP_Full.txt"]
        },
        {
            Number: "1",
            Name: "Customer Service",
            Gender: "Female",
            Accent: "American",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/customerService_Female.txt"]
        },
        {
            Number: "0",
            Name: "Rod Serling",
            Gender: "Male",
            Accent: "Trans Continental",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/RodSterling_TZ_1.txt"]
        },
        {
            Number: "0",
            Name: "Scottish Man",
            Gender: "Male",
            Accent: "Trans Continental",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/Male_Scottish_01.txt"]
        },
        {
            Number: "0",
            Name: "German Man A",
            Gender: "Male",
            Accent: "German",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/Male_German_01.txt"]
        },
        {
            Number: "0",
            Name: "German Man B",
            Gender: "Male",
            Accent: "German",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/Male_German_02.txt"]
        },
        {
            Number: "0",
            Name: "Boston Man A",
            Gender: "Male",
            Accent: "Trans Continental",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/Male_Boston_01.txt"]
        },
       
        {
            Number: "0",
            Name: "Boston Man B",
            Gender: "Male",
            Accent: "Trans Continental",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/Male_Boston_02.txt"]
        },
       
       
        {
            Number: "0",
            Name: "Dutch English Man",
            Gender: "Male",
            Accent: "Trans Continental",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/Male_DutchEnglish.txt"]
        },
       
        {
            Number: "1",
            Name: "Morgan Freeman",
            Gender: "Male",
            Accent: "American",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/mfreeman.txt"]
        },
        {
            Number: "2",
            Name: "Jackie Chan",
            Gender: "Male",
            Accent: "American",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/jackieChan.txt"]
        },
        {
            Number: "1",
            Name: "Ronald Reagan",
            Gender: "Male",
            Accent: "American",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/reagan.txt"]
        },
        {
            Number: "2",
            Name: "Mario",
            Gender: "Male",
            Accent: "American",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/mario.txt"]
        },
        {
            Number: "2",
            Name: "SpongeBob",
            Gender: "Male",
            Accent: "American",
            Age: "Adult",
            Traits: "Young",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/spongebob.txt"]
        },
   
        {
            Number: "1",
            Name: "Portal",
            Gender: "Female",
            Accent: "American",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/portal.txt"]
        },
        {
            Number: "1",
            Name: "Goku",
            Gender: "Male",
            Accent: "American",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/goku.txt"]
        },
        {
            Number: "2",
            Name: "Vegeta",
            Gender: "Male",
            Accent: "American",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/vegeta.txt"]
        },
        {
            Number: "1",
            Name: "Fresh Prince",
            Gender: "Male",
            Accent: "American",
            Age: "Adult",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/freshPrince.txt"]
        },
        {
            Number: "2",
            Name: "Sonic",
            Gender: "Male",
            Accent: "American",
            Age: "Young",
            Traits: "Old",
            URL:["https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/audio/texttovoice/deps/voices/varied/sonic.txt"]
        }
    ]
}
        
export const vitsVoices = {
    model: "en/vits",
    speakers: [
        {
            Number: "0",
            Name: "Heady",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "1",
            Name: "Eliza",
            Gender: "Feminine",
            Accent: "Eastern European",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "2",
            Name: "Henry",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "3",
            Name: "Anna",
            Gender: "Feminine",
            Accent: "Eastern European",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "4",
            Name: "Noah",
            Gender: "Male",
            Accent: "Robot",
            Age: "Teen",
            Traits: "High",
            "": "Delete"
        },
        {
            Number: "5",
            Name: "Samuel",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "6",
            Name: "Gray",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "7",
            Name: "James",
            Gender: "Male",
            Accent: "Robot",
            Age: "Adult",
            Traits: "Middle",
            "": "Delete"
        },
        {
            Number: "8",
            Name: "Oliver",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "9",
            Name: "Pavan",
            Gender: "Male",
            Accent: "Indian",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "10",
            Name: "Tom",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "11",
            Name: "Martin",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "High",
            "": ""
        },
        {
            Number: "12",
            Name: "Amber",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "13",
            Name: "Benjamin",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "14",
            Name: "Theo",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "15",
            Name: "Elaine",
            Gender: "Feminine",
            Accent: "Robot",
            Age: "Adult",
            Traits: "Middle",
            "": "Delete"
        },
        {
            Number: "16",
            Name: "Colton",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "17",
            Name: "Olivia",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "High",
            "": ""
        },
        {
            Number: "18",
            Name: "Eleanor",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "19",
            Name: "Ava",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "20",
            Name: "Maisie",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "21",
            Name: "Edith",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "22",
            Name: "Esmee",
            Gender: "Femine",
            Accent: "Robot",
            Age: "Teen",
            Traits: "High",
            "": "Delete"
        },
        {
            Number: "23",
            Name: "Ada",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "24",
            Name: "Amelia",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "25",
            Name: "Conrad",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "26",
            Name: "Albert",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "27",
            Name: "Braxton",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "28",
            Name: "Harrison",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "29",
            Name: "Tanner",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "30",
            Name: "Wesley",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "31",
            Name: "Blaine",
            Gender: "Both",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "32",
            Name: "Milo",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "33",
            Name: "Lyla",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "34",
            Name: "Audrey",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "35",
            Name: "Ivy",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "36",
            Name: "Sutton",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "37",
            Name: "Heather",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "38",
            Name: "George",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "39",
            Name: "Thomas",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "40",
            Name: "Leo",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "41",
            Name: "Conrad",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "42",
            Name: "Ivory",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "43",
            Name: "Jim",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "44",
            Name: "Katie",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "45",
            Name: "Mary",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "46",
            Name: "Finley",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "47",
            Name: "Phoebe",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "48",
            Name: "Emily",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "49",
            Name: "Harper",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "50",
            Name: "Alexis",
            Gender: "Both",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "51",
            Name: "Harper",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "52",
            Name: "Delilah",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "53",
            Name: "Jaxon",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "54",
            Name: "Kendell",
            Gender: "Feminine",
            Accent: "American",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "55",
            Name: "Louis",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "56",
            Name: "Rowan",
            Gender: "Both",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "57",
            Name: "Robyn",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "58",
            Name: "RoboLucy",
            Gender: "Feminine",
            Accent: "Robot",
            Age: "Adult",
            Traits: "Middle",
            "": "Delete"
        },
        {
            Number: "59",
            Name: "Otis",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "60",
            Name: "Gabriel",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "61",
            Name: "Rupert",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "62",
            Name: "Clara",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "63",
            Name: "Brody",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "64",
            Name: "Elodie",
            Gender: "Feminine",
            Accent: "Robot",
            Age: "Adult",
            Traits: "Middle",
            "": "Delete"
        },
        {
            Number: "65",
            Name: "Hannah",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "66",
            Name: "Myla",
            Gender: "Feminine",
            Accent: "Robot",
            Age: "Adult",
            Traits: "Middle",
            "": "Delete"
        },
        {
            Number: "67",
            Name: "Sara",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "68",
            Name: "Tobias",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "69",
            Name: "Harvey",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "70",
            Name: "Summer",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "High",
            "": ""
        },
        {
            Number: "71",
            Name: "Myla",
            Gender: "Feminine",
            Accent: "Indian",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "72",
            Name: "Kai",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "73",
            Name: "Beatrice",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "74",
            Name: "Abigail",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "High",
            "": ""
        },
        {
            Number: "75",
            Name: "Nellie",
            Gender: "Feminine",
            Accent: "Robot",
            Age: "Adult",
            Traits: "High",
            "": "Delete"
        },
        {
            Number: "76",
            Name: "Madison",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "77",
            Name: "Miles",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "78",
            Name: "Nora",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "79",
            Name: "Felicia",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "80",
            Name: "Marnie",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "81",
            Name: "Patrick",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "82",
            Name: "Vincent",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "83",
            Name: "Niamh",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "84",
            Name: "Elena",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "85",
            Name: "Evan",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Deep",
            "": ""
        },
        {
            Number: "86",
            Name: "Lincoln",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "High",
            "": ""
        },
        {
            Number: "87",
            Name: "Naomi",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "88",
            Name: "Maxwell",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "89",
            Name: "Callie",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "90",
            Name: "Callum",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "91",
            Name: "Aoife",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "92",
            Name: "Paige",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "93",
            Name: "Raya",
            Gender: "Feminine",
            Accent: "British",
            Age: "Teen",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "94",
            Name: "Maia",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "95",
            Name: "Nyla",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "96",
            Name: "Rafe",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "97",
            Name: "Flora",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "98",
            Name: "Seren",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "99",
            Name: "Diya",
            Gender: "Feminine",
            Accent: "Indian",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "100",
            Name: "Talia",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "101",
            Name: "Eden",
            Gender: "Both",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "102",
            Name: "Margaret",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "103",
            Name: "Lucia",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "104",
            Name: "Rebecca",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "105",
            Name: "Daphne",
            Gender: "Feminine",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "106",
            Name: "Brooke",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "107",
            Name: "Harley",
            Gender: "Male",
            Accent: "British",
            Age: "Adult",
            Traits: "Middle",
            "": ""
        },
        {
            Number: "108",
            Name: "Barney",
            Gender: "Male",
            Accent: "Robot",
            Age: "Adult",
            Traits: "Middle",
            "": "delete"
        }
    ]
}