

import axios from "axios";

export const createImageRequest = (base64SEncodedImage, style: "fruits", prompt: "make it a AG153ppi painting. Tasteful Contrast. in the style of Guiseppi Arcimboldo", negativeprompt: "small fruits and vegetables. low quality. blurry. high contrast.", model: "prompthero/openjourney", subModel:"pix2pix", seed: 316, steps: 20, num_images_per_prompt: 1, controlnet_conditioning_scale: 1.33, guidance_scale: 7.5, low_threshold: 60, high_threshold: 30, rotationx: 0, rotationy: 0, rotationz: 0, scalex: 1, scaley: 1, scalez: 1) => {

  return{
   input:base64SEncodedImage,  style: style, prompt: prompt, negativeprompt: negativeprompt, model: model, subModel:subModel, seed: seed, steps: steps, num_images_per_prompt: num_images_per_prompt, controlnet_conditioning_scale: controlnet_conditioning_scale, guidance_scale: guidance_scale, low_threshold: low_threshold, high_threshold: high_threshold, rotationx: rotationx, rotationy: rotationy, rotationz: rotationz, scalex: scalex, scaley: scaley, scalez: scalez
  }

}

export const createYearbookImageRequest = (base64SEncodedImage,  prompt: "", negativeprompt: "", seed: 316, steps: 50, num_images_per_prompt: 3, guidance_scale: 7.5, width: 512, height:512, faceFix:1, faceFixWeight:0.8, scaleFidelity = 1.0) => {

 let  request = {
    model: "/root/models/sd/yearbook/90s",
    image: base64SEncodedImage,
    prompt: prompt,
    negative_prompt: negativeprompt,
    imageToGenerate:  num_images_per_prompt != "" ? num_images_per_prompt : 3,
    seed:seed,
    steps:steps,
    scale: 1.0,
    s_scale: scaleFidelity,
    guidance_scale: guidance_scale,
    width:width,
      height:height,
      faceFix:faceFix,
      version:"1.3",
      upscale:1,
      bg_upsampler:"realesrgan",
      bg_tile:400,
      suffix:"None",
      only_center_face:false,
      aligned:false,
      ext:"auto",
      weight :faceFixWeight,
  }

  return request;

}

export const createRealVisionImageRequest = (base64SEncodedImage,  prompt: "", negativeprompt: "", seed: 316, steps: 50, num_images_per_prompt: 3, guidance_scale: 7.5, width: 512, height:512, faceFix:1, faceFixWeight:0.8, scaleFidelity = 1.0) => {

 let  request = {
    model: "SG161222/Realistic_Vision_V6.0_B1_noVAE",
    image: base64SEncodedImage,
    prompt: prompt,
    negative_prompt: negativeprompt,
    imageToGenerate:  num_images_per_prompt != "" ? num_images_per_prompt : 3,
    seed:seed,
    steps:steps,
    scale: 1.0,
    s_scale: scaleFidelity,
    guidance_scale: guidance_scale,
    width:width,
      height:height,
      faceFix:faceFix,
      version:"1.3",
      upscale:1,
      bg_upsampler:"realesrgan",
      bg_tile:400,
      suffix:"None",
      only_center_face:false,
      aligned:false,
      ext:"auto",
      weight :faceFixWeight,
  }

  return request;

}


export const Pix2PixAugmentImage = async (base64SEncodedImage, style: "fruits", prompt: "make it a AG153ppi painting. Tasteful Contrast. in the style of Guiseppi Arcimboldo", negativeprompt: "small fruits and vegetables. low quality. blurry. high contrast.", model: "prompthero/openjourney", subModel:"pix2pix", seed: 316, steps: 20, num_images_per_prompt: 1, controlnet_conditioning_scale: 1.33, guidance_scale: 7.5, low_threshold: 60, high_threshold: 30, rotationx: 0, rotationy: 0, rotationz: 0, scalex: 1, scaley: 1, scalez: 1) => {

    const req = createImageRequest(base64SEncodedImage, style, prompt, negativeprompt, model, subModel, seed, steps, num_images_per_prompt, controlnet_conditioning_scale, guidance_scale, low_threshold, high_threshold, rotationx, rotationy, rotationz, scalex, scaley, scalez);
    console.log("req");
    console.log(req);
  const response = axios.post("https://vltmedia--aiphotobooth-guiseepearcimboldo-01-pix2pix.modal.run/",req , {headers: {'Access-Control-Allow-Origin': '*'}})
    return response;
}


export const YearbookAugmentImage = async (base64SEncodedImage,  prompt: "", negativeprompt: "", seed: 316, steps: 50, num_images_per_prompt: 3, guidance_scale: 7.5, width: 512, height:512, faceFix:1, faceFixWeight:0.8, scaleFidelity = 1.0) => {

    const req = createYearbookImageRequest(base64SEncodedImage,  prompt, negativeprompt, seed, steps, num_images_per_prompt, guidance_scale, width, height, faceFix, faceFixWeight, scaleFidelity);
  const response = axios.post("https://bravomedianyc--faceencoder-flask-app.modal.run/yearbook/90s",req , {headers: {'Access-Control-Allow-Origin': '*'}})
    return response;
}

export const CustomFaceIDAugmentImage = async (base64SEncodedImage,  prompt: "", negativeprompt: "", seed: 316, steps: 50, num_images_per_prompt: 3, guidance_scale: 7.5, width: 512, height:512, faceFix:1, faceFixWeight:0.8, scaleFidelity = 1.0) => {

    const req = createRealVisionImageRequest(base64SEncodedImage,  prompt, negativeprompt, seed, steps, num_images_per_prompt, guidance_scale, width, height, faceFix, faceFixWeight, scaleFidelity);
  const response = axios.post("https://bravomedianyc--faceencoder-flask-app.modal.run/facePlus",req , {headers: {'Access-Control-Allow-Origin': '*'}})
    return response;
}
