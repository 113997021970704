import React from "react";
import {isMobile} from 'react-device-detect';
import { useSelector, useDispatch } from "react-redux";
import LoadingIcons from 'react-loading-icons'
import Paper from '@mui/material/Paper';
import ReactAudioPlayer from 'react-audio-player';

import axios from "axios";
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect, useRef, useState} from 'react';
import { styled } from '@mui/material/styles';
import ReactPlayer from 'react-player'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { Box, Container } from '@mui/system';
import { TextField, FormControl, Button } from "@mui/material";
import { Link } from "react-router-dom"
export const FaceTalker = () => {
    const [videoFile, setVideoFile] = useState(null);
    const [videoUrl, setvideoUrl] = useState("https://www.dropbox.com/scl/fi/3wyadlb61e6tmlcp8dw23/129_2sec.mp4?rlkey=kondqa780kxkik4yd14wwvv0z&raw=1");
    const [responseVideoUrl, setResponseVideoUrl] = useState("https://www.dropbox.com/scl/fi/3wyadlb61e6tmlcp8dw23/129_2sec.mp4?rlkey=kondqa780kxkik4yd14wwvv0z&raw=1");
    const [audioUrl, setaudioUrl] = useState("https://www.dropbox.com/scl/fi/djyxlmfkqkz8drqafw9tb/audio_2sec.wav?rlkey=v8gqk9y594r27qeqwwyj9njp9&raw=1");
    const [audioFile, setAudioFile] = useState(null);
    const hiddenFileInput = useRef(null); 
    const hiddenFileInput2 = useRef(null); 

    
    const displayVideo  = (a_file) =>{
        const fileReader = new FileReader()
        fileReader.readAsDataURL(a_file)
        fileReader.onload = () => {
    setvideoUrl(fileReader.result);
  }
      }
    const file_to_base64 = async (a_file: File) => {
        let a_function = 
          (file: File) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              let base64_string = String(reader.result).split(",")[1]
              resolve(base64_string)
            };
            reader.onerror = error => reject(error);
          })
        let base64 = await a_function(a_file);
          return base64;
      }
      const blobToBase64= async(blob) =>{
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }
      const faceCrop = async (base64String) => {
        const url = "https://basicphotouploader-flask-7nd4c.ondigitalocean.app/photouploader-flask2/video/crop/face/proxy";

        const data = {
          "input": base64String,
          "size": [128, 128],
          "padding":5
        }
        const response = await axios.post(url, data);
        console.log(response);
        return {"frames":response.data.keys}
      }

      const animateFaceLips = async (frames) => {
        
        const url = "https://basicphotouploader-flask-7nd4c.ondigitalocean.app/photouploader-flask2/proxy";
        const url2 = "https://bravomedianyc--faceanimator-structured-iplap-generateface.modal.run";
        const data1 = {
          
          "input": frames.frames,
          "fileName": "input.mp4",
          "audio": "https://www.dropbox.com/scl/fi/20jljbajo0rw6nha00lki/Deniro.wav?rlkey=n2a214v7b4jm1kbzoi8fxvdh0&raw=1",
          "static":false
        }
        const data = {
            "url":"https://bravomedianyc--faceanimator-structured-iplap-flaskapp-.modal.run/generate/face/ip",
            "request":data1}

        const response = await axios.post(url2, data1, {headers: {'Access-Control-Allow-Origin': '*'}, responseType: 'blob'});
        // convert blob to base64
        const responseurl_ = window.URL.createObjectURL(new Blob([response.data]));

        // const base64 = await blobToBase64(new Blob([response.data]));
        setResponseVideoUrl(responseurl_)
        // return {"frames":response.data.keys}
      }

    const handleSubmit = async (e) => {

        e.preventDefault();
       await startProcess(e);
    }
    const downloadFaceClicked = async (e) => {

        e.preventDefault();
        const link = document.createElement('a');
        link.href = responseVideoUrl;
        link.setAttribute('download', 'file.mp4'); //or any other extension
        document.body.appendChild(link);
        link.click();
        // link.remove();
    }
    const importFaceClicked = async (e) => {
        hiddenFileInput.current.click();   
        e.preventDefault();
    }

    const importAudioClicked = async (e) => {
        hiddenFileInput2.current.click();   
        e.preventDefault();
    }

    const startProcess = async (e) => {
        e.preventDefault();
        const videoBase64 = await file_to_base64(videoFile);
        const Faceframes = await faceCrop(videoBase64);
        await animateFaceLips(Faceframes);

        // const audioBase64 = await file_to_base64(audioFile);
        // console.log(videoBase64);
        // console.log(audioBase64);
    }
    const handleChange = async (event) => {
        const fileUploaded = event.target.files[0]; // ADDED
        const videoBase64 = await file_to_base64(fileUploaded);

        setvideoUrl( displayVideo(fileUploaded));
        setVideoFile(fileUploaded);
      };
    const handleChange2 = async (event) => {

        const fileUploaded = event.target.files[0]; // ADDED
        const fileReader = new FileReader()
        fileReader.readAsDataURL(fileUploaded)
        fileReader.onload = () => {
            setaudioUrl(fileReader.result);
        setAudioFile(fileUploaded);

      };
    };
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });
    return (

        <React.Fragment>
      <CssBaseline />
      <Container fixed>
      <Box sx={{ bgcolor: 'transparent', height: '100%' }} >
      <form autoComplete="off" onSubmit={handleSubmit}>
            <h2>Make Face Speak</h2>
            <div className="buttonsVerticalHolder itemsHolder">
            <input 
        type="file"
        accept="video/mp4"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{display:'none'}}
      />


<Paper elevation={3} className="aiItemCard-Paper">
    <div className="aiItemCardsContainer">
    <h1 className="headerText">Input</h1>
<div className="widthFitParent aiItemCards">

<Card sx={{ maxWidth: 345 }} className="aiItemCard">
<ReactPlayer className="widthFitParent " url={videoUrl} controls={true}  />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Face Video
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Handles the basic head and body movement.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={importFaceClicked}>Upload File</Button>
      </CardActions>
    </Card>


<Card sx={{ maxWidth: 345 }}>
    <div className="widthFitParent audioCard">
<ReactAudioPlayer
  src={audioUrl}
  controls
  className="audioCard-Player"
/>
</div>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Voice File
        </Typography>
        <Typography variant="body2" color="text.secondary">
        The voice file you want to use to animate the lips and mouth.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={importAudioClicked}>Upload File</Button>
      </CardActions>
    </Card>


    </div>
    </div>




            <input 
        type="file"
        accept="audio/wav"
        onChange={handleChange2}
        ref={hiddenFileInput2}
        style={{display:'none'}}
      />

</Paper>

            </div>




            <Paper elevation={3} className="aiItemCard-Paper">
    <div className="aiItemCardsContainer">
    <h1 className="headerText">Results</h1>
<div className="widthFitParent aiItemCards">
<Card sx={{ maxWidth: 345 }} className="aiItemCard">
<ReactPlayer className="widthFitParent " url={responseVideoUrl} controls={true}  />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Result
        </Typography>
        <Typography variant="body2" color="text.secondary">
        The result
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={downloadFaceClicked}>Download File</Button>
      </CardActions>
    </Card>
</div>
</div>

</Paper>





            <Paper elevation={3} className="aiItemCard-Paper">

           
</Paper>
            
            




                 <Button variant="outlined" color="secondary" type="submit">Login</Button>
             
        </form>

</Box>
      </Container>
    </React.Fragment>
    );

}
