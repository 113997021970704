import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import {codeMap ,getCode} from '../../../codes';
import { useSelector, useDispatch } from "react-redux";
import { addItem, deleteItem, setCode, setCodeObj, reset } from "../../../reducers/cartReducer";
import {updateViaCode} from '../../../utils/api';
import {useEffect} from 'react';
import {updateExperience} from '../../../reducers/cartReducer';
import {VideoItemsCMS} from '../VideoItems/videoItemsCMS';
import {ShowAppHomeButtons} from './ShowAppButtons';
import axios from "axios";
export  const ShowAppHome = ({setselectedScene}) => {
  const state = useSelector((state) => state);
  const openCMS = () => {
    setselectedComponent(<VideoItemsCMS/>);
    console.log("openCMS");
  }
  
  const downloadPCApp = () => {
    const url = state.selectedExperience.params[0].presets.builds.mac[0].url;
    // download the url here
    const a = document.createElement("a");
    a.href = url;
    a.download = "download";
    a.click();
    //
    console.log("downloadPCApp");
  }

  const [selectedComponent, setselectedComponent] = React.useState(<ShowAppHomeButtons openCMS={openCMS} downloadPCApp={downloadPCApp} />);
  const dispatch = useDispatch();
  const onLoggedIn = () => {
    console.log("onLoggedIn");
  }


  

  useEffect(() => {
    // Update the document title using the browser API
  }, []);


    return(

      <div className="">
        {selectedComponent}

      </div>


    ) 
    ;
}