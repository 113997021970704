import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    Input,
    Checkbox,
    Textarea ,
    Button,
    Typography,
    Select, Option 
  } from "@material-tailwind/react";
  import ReactAudioPlayer from 'react-audio-player';

  import { Gallery } from "react-grid-gallery";
  import {setAISettings} from '../../reducers/cartReducer';
  import { TTSGeneration } from './components/TTSGeneration';
  import MediaTable  from './components/MediaTable';
import axios from 'axios';
import { AISettings } from '../../actionTypes/aiSettings';
export const TTSScene = () =>{
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const ttsHeaders = ['Index',"Commands",'Text', 'Model', 'Speaker'];
    
    const MediaSelected = async (newMedia) => {
        let newSettings = state.aiSettings;
        newSettings.currentMedia = newMedia;
        dispatch(setAISettings(newSettings));
        setmediaPlayer(    <ReactAudioPlayer
            src={newMedia.media}
            controls
            autoPlay

            className="audioCard-Player pb-3"
          />);

    }
    const [mediaTable, setmediaTable] = React.useState(<MediaTable headers={ttsHeaders} rows={[]} onSelected={()=>{}} />);
    const [mediaPlayer, setmediaPlayer] = React.useState(undefined);

    
    const  onDelete = (item) => {
        let newState = state.aiSettings.media.filter((media) => media !== item);
        newState.media = state.aiSettings.media.filter((media) => media !== item);
        dispatch(setAISettings(newState));
        setmediaTable(<MediaTable headers={ttsHeaders} rows={newState.media} onSelected={MediaSelected} onDelete={onDelete} />);
    }

    useEffect(() => {

        if(state.aiSettings.media == undefined){

            let newSettings = new AISettings();
            dispatch(setAISettings(newSettings));
        }
        setmediaTable(<MediaTable headers={ttsHeaders} rows={state.aiSettings.media} onSelected={MediaSelected} onDelete={onDelete} />);
        
        setmediaPlayer(    <ReactAudioPlayer
            src={state.aiSettings.media ? state.aiSettings.media.media : ""}
            controls
            className="audioCard-Player pb-3"
          />);


    }, [state.aiSettings.media]);

    const SetMedia =  (newMedia) => {
    
        // add new media to the list
        let newSettings = state.aiSettings;
        newSettings.media.push(newMedia);
        dispatch(setAISettings(newSettings));
        setmediaTable(<MediaTable headers={ttsHeaders} rows={newSettings.media} onSelected={MediaSelected} onDelete={onDelete} />);
    }
        
    const RecievedMP3 = async (newMedia) => {
    
        // add new media to the list
        SetMedia(newMedia);

    }
    const RecievedWav = async (newMedia) => {
        // add new media to the list
        SetMedia(newMedia);
    }
    

    return (
         <div className="ImageGenContainer w-full" >
         <div className="ImageGenContainer-Item ml-3" >
     <TTSGeneration NewRecieved={SetMedia} recievedWav={RecievedWav}recievedMP3={RecievedMP3}/>
</div >
<div className="ImageGenContainer-Item min-h-50vh max-h50vh max-w-1/2 ml-3 mr-3 inline w-full h-2/3 rounded-lg" >
{mediaPlayer}

{mediaTable}




</div >

         {/* <div className="ImageGenContainer-Item" >
     
</div> */}
</div>
    )


}