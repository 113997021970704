import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    Input,
    Checkbox,
    Textarea ,
    Button,
    Typography,
    Slider,
    Select, Option 
  } from "@material-tailwind/react";
  import { faMars, faVenus  } from '@fortawesome/free-solid-svg-icons'
import { faFileImport, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import LoadingIcons from 'react-loading-icons'
  import {uuidv4, file_to_base64} from '../../../utils/api';
import axios from 'axios';
import {xttsVoices} from "../constants/models";
import ReactAudioPlayer from 'react-audio-player';
export const XTTS = ({NewRecieved}) =>{

    const [models, setModels] = React.useState([ 
    <Option value={"Custom"}>Custom</Option>,
    <Option value={"Rod Serling"}>Rod Serling</Option>,
    <Option value={"Martin Freemen"}>Martin Freemen</Option>,
  ]);
  const maxSteps = 40;
  const hiddenFileInput = useRef(null); 
  
    const [model, setModel] = React.useState("Custom");
    const [previewComponent, setPreviewComponent] = React.useState(<ReactAudioPlayer
      src={""}
      controls

      className="audioCard-Player"
    />);
    const [prompt, setPrompt] = React.useState("");
    const [steps, setsteps] = React.useState(20);
    const [base64File, setBase64File] = React.useState("");
    const [mode, setMode] = React.useState("input");
    const Divider = () => {
      return (
          <hr
              className="Divider"
          ></hr>
      );
  };

  const SendRequest = async (outReq) => {

    const resp = await axios.post("https://bravomedianyc--texttovoice-vits-flask-app.modal.run/xtts", outReq,    {
        'Access-Control-Allow-Origin': '*'
    });
    // const resspData = JSON.parse( resp.data.body);
    // remove doublequotes from beginning and end of resp.data
    let respData = resp.data;
    // respData = respData.substring(1, respData.length - 1);
    

    const outResponse = {
        "media": respData,
        "prompt": prompt,
        "steps": steps,
        "model": "XTTS",
        "gender": outReq.gender,
        "speaker": model,
        "date": new Date().toLocaleString(),
        "id": uuidv4()
    }
    NewRecieved(outResponse);
    setMode("input");

    }


    const RunProcess = async () => {
      setMode("loading");
      let appended = "";
      if(model.includes( "wavymulder/Analog-Diffusion")){
        appended = "analog style ";
      }
      let speaker = base64File;
      if(model != "Custom"){
        let matchingSpeaker = xttsVoices.speakers.filter(item => item.Name == model)[0];
    let outReq = {
      "text": appended+ prompt,
      "language": "en",
      "speaker": [],
      "decoderIteration": steps,
      "gender": matchingSpeaker.Gender,
  }
  localStorage.setItem("XTTSReq", JSON.stringify(outReq));
  outReq.speaker = matchingSpeaker.URL;
  SendRequest(outReq);
      }else{

    let outReq = {
        "text": appended+ prompt,
        "language": "en",
        "speaker": "",
        "decoderIteration": steps,
      "gender": "Custom",

    }
    localStorage.setItem("XTTSReq", JSON.stringify(outReq));
    outReq.speaker = [speaker];
    SendRequest(outReq);
  }
  }

  

  //   const RunProcess = async () => {
  //     setMode("loading");
  //     let appended = "";
  //     if(model.includes( "wavymulder/Analog-Diffusion")){
  //       appended = "analog style ";
  //     }
  //     let speaker = base64File;
  //     if(model != "Custom"){
  //       let matchingSpeaker = xttsVoices.speakers.filter(item => item.Name == model)[0];
  //       let downloadURL = matchingSpeaker.URL;
  //       const resp = await axios.get(downloadURL,    {
  //         'Access-Control-Allow-Origin': '*'
  //     }).then((response) => {
  //       // create a base64 string
  //       const b64 = response.data;
  //       speaker = b64;
        
  //   let outReq = {
  //     "text": appended+ prompt,
  //     "language": "en",
  //     "speaker": "",
  //     "decoderIteration": steps,
  //     "gender": matchingSpeaker.Gender,
  // }
  // localStorage.setItem("XTTSReq", JSON.stringify(outReq));
  // outReq.speaker = speaker;
  // SendRequest(outReq);
  //     });
  //     }else{

  //   let outReq = {
  //       "text": appended+ prompt,
  //       "language": "en",
  //       "speaker": "",
  //       "decoderIteration": steps,
  //     "gender": "Custom",

  //   }
  //   localStorage.setItem("XTTSReq", JSON.stringify(outReq));
  //   outReq.speaker = speaker;
  //   SendRequest(outReq);
  // }
  // }

  

    const importMediaClicked = async (e) => {
      hiddenFileInput.current.click();   
      e.preventDefault();
  }
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0]; // ADDED
    const videoBase64 = await file_to_base64(fileUploaded);
    const wavBase64 = "data:audio/wav;base64," + videoBase64;
    setBase64File(videoBase64);
    setPreviewComponent(<ReactAudioPlayer
      src={wavBase64}
      controls
      className="audioCard-Player "
    />);
  };


    useEffect(() => {

      const req = localStorage.getItem("XTTSReq");
      let newItems = [<Option value={"Custom"}>Custom</Option>];
      xttsVoices.speakers.forEach(item =>{
        const gender = item.Gender == "Male" ?   <FontAwesomeIcon icon={faMars} className=" text-xs mr-3 text-blue-800 cursor-pointer" /> : <FontAwesomeIcon icon={faVenus} className=" text-xs mr-3 text-pink-400 cursor-pointer" /> 

        newItems.push(<Option value={item.Name}>{gender} {item.Name}</Option>);
      });
      setModels(newItems);
      if(req != null){
        const reqObj = JSON.parse(req);
        setPrompt(reqObj.prompt);
        setModel(reqObj.model);
      }
    }
    ,[]);

    return (
         <Card  className="w-auto" color="white" shadow={true} >
      <div className="p-10">
        <form className="mt-1 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-1 flex flex-col gap-6">
          <Typography color="black" className="text-sm font-bold">
          XTTS
        </Typography>
        <Typography color="gray" className="mt-1 text-xs">
          A Model used to generate decent voice clones, but it is hit or miss. A custom file can be uploaded to generate a voice clone "cousin" of that person. Max length of input audio is 2 minutes.
        </Typography>
    

          <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Voice
            </Typography>

          <Select variant="outlined" id="showSelect" label="Model" onChange={(e) => {setModel(e)}}>
                  {models}
                </Select>

            
{         model == "Custom" ?
<>
          <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Voice File
            </Typography>
            <Typography color="red" className="mt-1 text-xs">
        Wav Mono 41khz 16 bit 20 MB max.
        </Typography>
            <div className="flex flex-row gap-2">
            <FontAwesomeIcon icon={faUpload} className=" text-base mr-3 text-blue-800 cursor-pointer place-self-center" onClick={importMediaClicked}/>
            {previewComponent}

</div>
            {/* <Button size="small" onClick={importMediaClicked}>Import File</Button> */}
          <Divider/>

            </> : undefined}

            
                <Typography variant="h6" color="blue-gray" className="headerInput">
              Text
            </Typography>
            <Textarea label="Text" 
            value={prompt}
              onChange={(e) => setPrompt(e.target.value)}  />
          {/* <div className="flex flex-row gap-2"> */}


    
            {/* </div> */}
          <Divider/>

  


          </div>
          {mode == "loading"?  <Button className="mt-6" fullWidth disabled={true} >
            Loading
          </Button> :  <Button className="mt-6" fullWidth onClick={RunProcess}>
            Generate
          </Button>}
        </form>
</div>
<input 
        type="file"
        accept="audio/wav"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{display:'none'}}
      />

</Card>
    )


}