
import * as React from 'react';

import axios from "axios";
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setPhotoboothSettingsMode, setImagesShot } from "../../../reducers/cartReducer";

import { Box, Container } from '@mui/system';
import CameraScene from '../components/cameraScene';
import ConfirmDialog from "../../../ui/confirmDialog";
import { uploadImage} from "../../../utils/api";
import InfoDialog from "../../../ui/infoDialog";
import {WebcamCapture} from '../components/cam/WebcamCapture';

import QRGen from '../components/cam/qr/qRGen';
global.Buffer = global.Buffer || require('buffer').Buffer;

export default function CameraPerson() {
    const state = useSelector((state) => state);
    const [scene, setScene] = React.useState("");
    const onResults = React.useRef(null)

    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [confirmTitle, setConfirmTitle] = React.useState("");
    const [confirmDescription, setConfirmDescription] = React.useState("");
    const [confirmUploadedOpen, setConfirmUploadedOpen] = React.useState(false);
    const [confirmUploadedTitle, setConfirmUploadedTitle] = React.useState("");
    const [confirmUploadedDescription, setConfirmUploadedDescription] = React.useState("");
    const [appData, setAppData] = React.useState({});
    const [appMetadata, setAppMetadata] = React.useState({});
    const [selectedImg, setSelectedImg] = React.useState(0);
    const [imgSrc,setImgSrc] = React.useState([]);
    const [picturesFull, setPicturesFull] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [firstRunMessageShown, setfirstRunMessageShown] = React.useState(false);
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [infoTitle, setInfoTitle] = React.useState("Info");
    const [infoBody, setInfoBody] = React.useState("-");
    const [infoAgree, setInfoAgree] = React.useState("Ok");
    const [watermark, setwatermark] = React.useState("");
    const [qrCode, setQrCode] = React.useState("Hello");
    const [appCode, setAppCode] = React.useState(null);
    const dispatch = useDispatch();
    const resetConfirmed = async () => {
        console.log("resetConfirmed");
        setPicturesFull(false);
        setConfirmUploadedOpen(false);
        setImgSrc([]);
        dispatch(setImagesShot([]));
        dispatch(setPhotoboothSettingsMode("camera"));

    }
    const showMessage = (title, message) => {
        const status = localStorage.getItem("showFirstMessage") == false || localStorage.getItem("showFirstMessage") == null || localStorage.getItem("showFirstMessage") == 'false' ? false : true;
        setInfoOpen(!status);
        console.log(status);
        localStorage.getItem("showFirstMessage", "true");
        setInfoTitle(title);
        setInfoBody(message);
    }
    const checkIfHidden = (key) => {
      return(state.selectedExperience.params[0].presets.hide.includes(key));
    }
    const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
      const reader = new FileReader;
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
    const convertWatermarkUrltoBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const base64 = await convertBlobToBase64(blob);
      return base64;
    }

    useEffect(() => {
      console.log(state.selectedExperience);
      const adata = state.selectedExperience.params[0];
      setAppData(adata);
      const ametadata = adata.presets;
      setAppMetadata(ametadata);
        // Update the document title using the browser API
       const scene_ =  localStorage.getItem("code")? "camera" : "codeLogin";
       const code = localStorage.getItem("code");
    const codeobj= JSON.parse( localStorage.getItem("codeObject"));
    // console.log(exp);
    dispatch(setPhotoboothSettingsMode("camera"));

    if(code != null){
        setAppCode(code);
        axios
        .get(ametadata.watermark, {
          responseType: 'arraybuffer'
        })
        .then(response => { setwatermark(Buffer.from(response.data, 'binary').toString('base64'))})
    
    }
       setScene(scene_);
       const loginStatus = localStorage.getItem("showFirstMessage");

       console.log(loginStatus);
       if(loginStatus == false || loginStatus == null|| loginStatus == 'false'){
        console.log("Inside");
        if(checkIfHidden("info.welcome") == false){
        showMessage("Welcome", "This app can be used to take photos at your event to be uploaded in an online folder. Take three photos and select the best one to upload!");
        setfirstRunMessageShown(true);
        }else{
        setfirstRunMessageShown(true);

        }
        // localStorage.getItem("showFirstMessage", "true");

    }
      }, []);


    const acceptConfirmed = async () => {
        console.log("acceptConfirmed");
        
        setConfirmOpen(false);
      dispatch(setPhotoboothSettingsMode("loading"));
        // if you want to crop the image, you can use the following line.
        // const resp = await uploadImage(selectedImg.replace("data:image/jpeg;base64,",""), appCode, watermark, [80,80,0,0], ["chest"], true );
        // if you don't want to crop the image, you can use the following line instead of the previous one
        const resp = await uploadImage(selectedImg.replace("data:image/jpeg;base64,",""), appCode, watermark, [80,80,0,0], ["chest"], false );
        if(resp.status == 200){
          if(checkIfHidden("confirm.restart") ==false){
        setConfirmUploadedOpen(true);
      dispatch(setPhotoboothSettingsMode("camera"));

        setConfirmUploadedTitle("Image Uploaded");
        setConfirmUploadedDescription("Your image has been uploaded successfully. Do you want to continue taking pictures or go back to the home screen?");
        }else{
        resetConfirmed();
        }
        }

    }
    

    const disagreedConfirmed = () => {
        console.log("disagreedConfirmed");
        setConfirmOpen(false);
    }
    
    const homeConfirmed = () => {
        console.log("homeConfirmed");
        setConfirmUploadedOpen(false);
        window.location.href = "/";
    }
    
    const setConfirmOpenWebcam = (state=false,title="", description="") => {
      if(checkIfHidden("confirm.image") ==false){
        setConfirmTitle(title);
        setConfirmDescription(description);
        console.log("acceptConfirmed");
        setConfirmOpen(state);
    }else{
      console.log("acceptConfirmed skipped");

      acceptConfirmed();
    }
    }

    const onQRScan = (data) => {
        console.log("onQRScan");
        console.log(data);
            setQrCode(data);
        setScene("came1ra");
    }

    const onCodeLoggedIn = () => {
setScene("camera");
    }
    
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        {/* <TopNav className="topNavBar"/> */}

      {scene == "qrView"? 
      <QRGen value={qrCode}  /> : 
      scene == "camera"? 
      <WebcamCapture setConfirmOpen={setConfirmOpenWebcam} selectedImg={selectedImg} setSelectedImg={setSelectedImg} isLoading={isLoading} imgSrc={imgSrc} setImgSrc={setImgSrc} picturesFull={picturesFull} setPicturesFull={setPicturesFull} setConfirmUploadedOpen={setConfirmUploadedOpen} imageCount={state.selectedExperience.params[0].presets.imageCount} overrideShare={state.selectedExperience.params[0].presets.overrideShareCount} onResults={onResults }/>:


      null
      
      }

      <ConfirmDialog open={confirmOpen} onClose={() => {setConfirmOpen(false)}} onAgree={acceptConfirmed} onDisagree={disagreedConfirmed} title={confirmTitle} description={confirmDescription}/>
      <ConfirmDialog open={confirmUploadedOpen} onClose={() => {setConfirmUploadedOpen(false)}} onAgree={resetConfirmed} onDisagree={homeConfirmed} title={confirmUploadedTitle} description={confirmUploadedDescription} agree="Continue" disagree='Home'/>
    
      <InfoDialog open={infoOpen} title={infoTitle} body={infoBody} agree={infoAgree} onClose={()=>{setInfoOpen(false);
    localStorage.setItem("showFirstMessage", true);
    }}/>

      </Container>
    </React.Fragment>
  );
}