

import axios from "axios";
import moment from "moment/moment";
import { useSelector, useDispatch } from "react-redux";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";

import app from "../utils/firebaseHandler";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import { addItem, deleteItem, setCode, setCodeObj, setExperience } from "../reducers/cartReducer";

const digitalOcean = "https://basicphotouploader-flask-7nd4c.ondigitalocean.app/photouploader-flask2";
const aws = "https://h6ye3gxbye.us-east-2.awsapprunner.com";
const localDev = "http://localhost:" + process.env.DEV_8080 != undefined? process.env.DEV_8080 : 8185;
const localDev_ = "http://localhost:8185" ;
// const flaskUrl = process.env.DEVMODE?  localDev : aws;
export const todayString = () => {
  return moment().local().format("YYYYMMDD");
}
export const uploadImage = async (base64SEncodedImage, showCode= "", watermark = "", padding=[80,80,0,0], targetPoints=["chest"], faceCrop=false ) => {
  const nowDateLocal = moment
  const response = axios.post(aws+"/upload/image", { image: base64SEncodedImage, code:showCode, date:todayString(), watermark:watermark, padding:padding, targetPoints:targetPoints, faceCrop:faceCrop  })
    return response;
}

export const getImagesByDate = async (dateString= "yyyyMMdd", showCode= "") => {
  const response = axios.post(aws+"/images/date/imageReview", { date: dateString , code:showCode}).catch((err) => {
    return null;
});
  try{
    return response;
  }catch(err){
    return null;}
    return response;
}
export const getWatermarkedImagesByDate = async (dateString= "yyyyMMdd", showCode= "") => {
  const response = axios.post(aws+"/images/watermarked/date", { date: dateString , code:showCode}).catch((err) => {
    return null;
});
  try{
    return response;
  }catch(err){
    return null;}
    return response;
}

export const getDates = async (showCode= "") => {
  const response = axios.post(aws+"/images/dates", {  code:showCode}).catch((err) => {
    return null;
});
  try{
    return response;
  }catch(err){
    return null;}
    return response;
}

export const deleteImage = async (url= "") => {
  const response = await axios.post(aws+"/image/delete", {  url:url}, {headers: {'Access-Control-Allow-Origin': '*'}}).catch((err) => {
    return null;
});
  try{

    return response;
  }catch(err){

    return null;}
    return response;
}

export const getExperienceByCode = async (showCode= "") => {

  const db = await getFirestore(app);
  const collection_ = collection(db, 'xperiences');
  const items = collection_;
  const querySnapshot = await getDocs(items);
  let match = {};
  for( var i = 0; i < querySnapshot.docs.length; i++){
      const doc = querySnapshot.docs[i];
      const item = doc.data();
      if (item.code === showCode) {
          match = item;
          break;
      }
  }
  return match;

  
  // try{
  //   alert(5);

  //   const resp = responsee.data[0];
  //   return resp;
  // }catch(err){
  //   alert(6);

  //   return null;}


}


export const updateExperience =  (dispatch, getState) =>{

  const state = getState();
  const code = state.code;
}

export const updateViaCode =  (dispatch, getState) =>{

  const state = getState();
  const code = state.code;
  // if(code.length != 5){
  //     alert("Code must be 5 digits");
  //   dispatch(setCode(""));

  //     return;
  // }
  console.log(code);
  if(code == ""){
      alert("Code must be entered");
    dispatch(setCode(""));

      return;
  }

  const db = getFirestore(app);
  
  const collection_ = collection(db, 'xperiences');
  const items = collection_;
  getDocs(items).then((querySnapshot) => {
    
    
  
  let match = {};
  for( var i = 0; i < querySnapshot.docs.length; i++){
      const doc = querySnapshot.docs[i];
      const item = doc.data();
      if (item.code === code) {
          match = item;
          break;
      }
  }
  console.log(match);
  if(match == {}){
    alert("Code not found");
    dispatch(setCode(""));
    return;
  }
  
  dispatch(setCode(code));
  dispatch(setCodeObj(match));
  
  localStorage.setItem("code",code);
  localStorage.setItem("codeObject",JSON.stringify(match));
  // match current state.selectedExperience.name to code_.experiences.name
  for (let i = 0; i < match.experiences.length; i++) {
    const element = match.experiences[i];
    if(element.name == state.selectedExperience.name){
      dispatch(setExperience(element));
      break;
    }
    
  }
});
  // onFinished();

  // onLoggedIn();
}

export const downloadMedia = (url_, onFinished, onError) => {
  var filename = url_.split('/').pop()
  axios.get(url_,
  {
      responseType: 'arraybuffer',
      headers: {
          'Access-Control-Allow-Origin': '*'
      }
  })
  .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      onFinished(url_);
  })
  .catch((error) => {
    console.log(error);
    console.log(url_);
    onError(url_);
  });
};

export const fileNameFromUrl =(url) => {
  var matches = url.match(/\/([^\/?#]+)[^\/]*$/);
  if (matches.length > 1) {
    return matches[1];
  }
  return null;
}

export const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


export const file_to_base64 = async (a_file: File) => {
  let a_function = 
    (file: File) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64_string = String(reader.result).split(",")[1]
        resolve(base64_string)
      };
      reader.onerror = error => reject(error);
    })
  let base64 = await a_function(a_file);
    return base64;
}
export const blobToBase64= async(blob) =>{
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}