import * as React from 'react';

import {codeMap ,getCode} from '../codes';
import {isMobile} from 'react-device-detect';
import { useResizeDetector } from 'react-resize-detector';
import { CodeLogin} from "../ui/codeSignin";
import { useSelector, useDispatch } from "react-redux";

import {ExperienceCard } from '../ui/experienceCard';
import {useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { Experiences } from '../ui/experiences';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Home = () => {
  const state = useSelector((state) => state);
  const { width, height, ref } = useResizeDetector();
  const [scrollClass, setScrollClass] = React.useState("" );
  const [code, setCode] = React.useState(null);

  useEffect(() => {
  //   // const code_ = localStorage.getItem("code");
  //   // const codeobj= JSON.parse( localStorage.getItem("codeObject"));
  //   const code_ = state.code;
  //   // console.log(exp);
  //   if(code_ != null){
  //     setCode(code_);
  //   }
  //   // }
  localStorage.setItem("showFirstMessage", false);
  }, []);
  const onLoggedIn = () => {
    console.log("onLoggedIn");
  }
  const onLoggedIn_ = () => {
    console.log("onLoggedIn");
  }
  

// console.log(codeobj.experiences);

    return(
      <>
     
     {state.code != null && state.code != "" && state.code != "code" ? <Experiences/> : <CodeLogin onLoggedIn={()=>{window.location="camera"}} />}

      </>


    ) 
    ;
  };
  
  export default Home;