
import React from "react";
import {useState} from "react";

export const ImageHolder = ({index, imgSrc, onClicked, classNme = "imageHolder", isSelected=false}) => {
    const [selected, setselected] = useState("");
    return (
        <div className={classNme + " " }>
            {
            <div className="imageHolder_intContainer">
                <button className="transparentButton"  onClick={() =>{
                    onClicked(imgSrc,index, this);
                }} ><img className={classNme == "imageHolder" ? "imageHolder_image": "previewImage_image"}
                    src={imgSrc != null ? imgSrc : "images/Bare_Gray.png"}
                /></button>
        </div>

            }
        </div>
    );
}

