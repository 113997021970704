

export class  VideoSceneDataParams
{
    constructor(){
    this.act = 1;
    this.sceneNumber = 0;
    this.autoplay = 1;
    this.loop = 0;
    this.sceneID = "A0000S0001";
    this.sceneName = "Untitled";
    this.sceneType = 6;
    this.nextScene="None";
    this.url = "";
    this.path = "";
    this.parameters = "{}";
}
}

export class  VideoSceneData
{
    constructor(){
    this.act = 1;
    this.sceneNumber = 0;
    this.sceneID = "A0000S0001";
    this.sceneName = "Untitled";
    this.sceneType = 'video';
    this.nextScene="None";
    this.url = "";
    this.path = "";
    this.parameters = "{}";
}
}


export class ExperiencePresets
{

}
export class ExperienceParam
{
    constructor(){
    this.id = 0;
    this.cameramode  = "";
    this.experience = "";
    this.subMode = "";
    this.endpoint = "";
    this.presets = new ExperiencePresets();
}
}

export  class ExperienceItem
{
    constructor(){
        this.id = -1;
        this.name = "";
        this.appType= "";
        this.title= "";
        this.description= "";
        this.thumbnail= "";
        this.url= "";
        this.help= "";
        this.audience= "";
        this.subType= "";
        this.tutorials = [];
        this.params = [new ExperienceParam()];
    }
   

}