import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    Input,
    Checkbox,
    Textarea ,
    Button,
    Typography,
    Select, Option 
  } from "@material-tailwind/react";
  import { Gallery } from "react-grid-gallery";

  import { ImageGeneration } from './components/ImageGeneration';

import axios from 'axios';
export const ImageGenerationScene = () =>{

    const [media, setmedia] = React.useState([]);
    const [images, setimages] = React.useState([]);
    const [selectedImage, setselectedImage] = React.useState("https://bravouai-photobooth-xgames.nyc3.digitaloceanspaces.com/images/deps/BG/BaseEmptyBG.png");
    
    const ImageSelected = async (newMedia) => {
        setselectedImage(images[newMedia].src);

    }
    
    const SetMedia = async (newMedia) => {
    
        // add new media to the list
        const newMediaList = media.concat(newMedia);
        setmedia(newMediaList);
        const outImages = [];
        newMediaList.forEach(element => {
            element.media.forEach(medd => {
                outImages.push({
                    src: "data:image/png;base64,"+medd.media,
                    width: 512,
                    height: 512,
                 });
                
            });
           
        });
        setimages(outImages);
        console.log(newMediaList);
        console.log(outImages);
    }

    return (
         <div className="ImageGenContainer" >
         <div className="ImageGenContainer-Item" >
     <ImageGeneration NewRecieved={SetMedia}/>
</div >
<div className="ImageGenContainer-Item ResultImagesContainer" >
    {selectedImage != null? 
<img src={selectedImage} alt="resultImage" className="ResultImage" /> : undefined
}
<Gallery images={images} onClick={ImageSelected} rowHeight={100}  className="ResultImages" />
</div >

         {/* <div className="ImageGenContainer-Item" >
     
</div> */}
</div>
    )


}