import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import StandardImageList from './StandardImageList';
import {getImagesByDate, getDates, deleteImage, downloadMedia, getWatermarkedImagesByDate} from '../utils/api';
import {setImagesToDownload} from '../reducers/cartReducer';
import ConfirmDialog from "../ui/confirmDialog";

import { FormControl, FormLabel } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Paper from '@mui/material/Paper';
import moment from 'moment'; 
import {ImageModal} from '../apps/photobooths/components/cam/imageModal';

export default function ImageReviewPage() {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const [watermarkedData, setwatermarkedData] = React.useState([]);
    const [itemData, setitemData] = React.useState([]);
    const [dates, setDates] = React.useState([]);
    const [isWatermarkMode, setIsWatermarkMode] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState("");
    const [imageModalOpen, setimageModalOpen] = React.useState(false);
    const [todayString, settodayString] = React.useState(moment().local().format("YYYYMMDD"));
    const [selectedDate, setSelectedDate] = React.useState(null);
    const dateTemplate = "yyyyMMdd";

    const download = (url, name = "download", type = "png") =>{
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = name + "." + type;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    const downloadError = (lastUrl) => {
      console.log("Error downloading " + lastUrl);
      if(state.imagesToDownload.length > 0){
        downloadNext(lastUrl);
      }
    }
    const downloadNext = (lastUrl) => {
      const urls = state.imagesToDownload;
      if (urls.length == 0) {
        return;
      }
      // remove lastUrl from urls
      const index = urls.indexOf(lastUrl);
      if (index > -1) {
        urls.splice(index, 1);
      }
      console.log("urls to download", urls);
      // download next
      dispatch(setImagesToDownload(urls));
      downloadMedia(urls[0], downloadNext, (lastUrl_)=>{console.log("Error downloading " + lastUrl_);
      if(state.imagesToDownload.length > 0){
        downloadNext(lastUrl_);
      }});
    }
    
    const DownloadWatermarkedImages= async()=>{
      // const imgs = watermarkedData;
        const imgs = await getWatermarkedImagesByDate(selectedDate, state.code);
        const itemData_ = imgs != null ? imgs.data.images : [];
        setwatermarkedData(itemData_);
    setSelectedDate(todayString);
    let imagesToDownload = [];
    for (let i = 0; i < itemData_.length; i++) {
      const element = itemData_[i];
      if(element.includes("002") === false && element.includes("003") === false && element.includes("001") == false && element.includes("004") === false )
      imagesToDownload.push(element);
    }
    dispatch(setImagesToDownload(imagesToDownload));
    downloadMedia(itemData_[0], downloadNext, downloadError);
    }

    const downloadMediaClicked = () => {
      // downloadMedia(img, (url_) => { }, (err) => { });
      let imagesToDownload = [];
      for (let i = 0; i < itemData.length; i++) {
        const element = itemData[i];
        imagesToDownload.push(element);
      }
      dispatch(setImagesToDownload(imagesToDownload));
      downloadMedia(itemData[0], downloadNext, downloadError);

    };

    const removeImage = async (img) => {
      const response = await deleteImage(img);
      setimageModalOpen(false);
      getImagesByDate_2(selectedDate);
    }

    async function getImagesByDate_2(date){
        const imgs = await getImagesByDate(date, state.code);
    setitemData(imgs != null ? imgs.data.images : []);
    setSelectedDate(date);
    console.log("imgs",imgs);
    console.log("todayDateStr",date);
    }
  
    React.useEffect(() => {
        
        const todayDateStr = moment().local().format("YYYYMMDD");
        console.log(todayDateStr);
        settodayString(todayDateStr);
    async function getImagesByDate_(date){
        const imgs = await getImagesByDate(date, state.code);
        try{
    setitemData(imgs.data.images);
    setSelectedDate(date);
    console.log("imgs",imgs);
    console.log("todayDateStr",date);
        }catch{
          
        }
    }

    async function getDates_(){
        const dates = await getDates(state.code);
        try{
          setDates(dates.data.dates);
    console.log("imgdatess",dates);
        }catch{
          
        }
    }
    getDates_();
        getImagesByDate_(todayDateStr);
    }, []);
    const DateChange = (newValue) => {
    const DateString = newValue.$y.toString() + (newValue.$M+1).toString() + newValue.$D.toString();
    getImagesByDate_2(DateString);
    }

    const onImageClicked = (item) => {
      console.log(item);
      setSelectedImage(item);
      setimageModalOpen(true);
    }
    
    const OpenImageModal = (state_) => {
      console.log(state_);
      setimageModalOpen(state_);
          }
    const acceptConfirmed = async () => {
      console.log("acceptConfirmed");
      
      setConfirmOpen(false);
      if(isWatermarkMode){
        DownloadWatermarkedImages();
      }else{
        downloadMediaClicked();

      }
      }

  const disagreedConfirmed = () => {
      console.log("disagreedConfirmed");
      setConfirmOpen(false);
  }
  
  return (
    <React.Fragment >
      <CssBaseline />
      <Paper elevation={3} className='paperArea PaperWide' >
      
      <Container className='ContainerWide'>
      <div className='imageReviewForm'>
      <FormLabel className='ReviewHeader-item'>Select Show Date</FormLabel>

<div className='ReviewHeader-FormControl-Container'>
      <FormControl fullWidth className='ReviewHeader-FormControl'>
  <NativeSelect
    defaultValue={todayString}
    inputProps={{
      name: 'date',
      id: 'uncontrolled-native',
    }}
    className='ReviewHeader-select'
    onChange={(e)=>{getImagesByDate_2(e.target.value)}}
    value={selectedDate}
  >
    {dates.map((date) => {

      return <option className='ReviewHeader-select' value={date}>{moment(date).format("dddd, MMM Do YYYY")}</option>
    })
    }

  </NativeSelect>
</FormControl>

<div className='buttonHolder'>
{itemData.length != 0 ? <button className='ReviewHeader-button bluebordered radiusSoft uaiButton' onClick={()=>{setConfirmOpen(true);
  setIsWatermarkMode(false);}}>Download All</button> : null}

{itemData.length != 0 ? <button className='ReviewHeader-button bluebordered radiusSoft uaiButton' onClick={()=>{
  setConfirmOpen(true);
  setIsWatermarkMode(true);
  }}>Download All Watermarked</button> : null}
</div>
</div>

      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker onAccept={DateChange} className='datePicker_'/>
    </LocalizationProvider> */}
        {itemData.length != 0 ? <StandardImageList itemData={itemData} onItemClicked={onImageClicked} /> : null}
        </div>

      </Container>
      </Paper>
      <ImageModal open={imageModalOpen} setOpen={OpenImageModal} img={selectedImage} removeImage={removeImage}/>
      <ConfirmDialog open={confirmOpen} onClose={() => {setConfirmOpen(false)}} onAgree={acceptConfirmed} onDisagree={disagreedConfirmed} title={"CONFIRM DOWNLOAD"} description={"Are you sure you want to download ALL the images from this show? \nIf so, please make sure you have your equivalent \"Ask me what to do with each download\" setting in your browser set to off to auto download all of these files. "} image="images/screenshots/autoDownload.jpg" agree="Ok" disagree='Cancel'/>

    </React.Fragment>
  );
}