// import React, useDispatch, useSelector and useState

import * as React from 'react';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import AIPix2Pix from "../AIPix2Pix/aiPix2Pix"; 
import Yearbook from "../YearBook/yearbook.js"; 
import CameraPerson from "../CameraPerson/cameraPerson"; 
import {updateViaCode} from '../../../utils/api';

export const CameraSelector = () => {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const [camera, setCamera] = React.useState(<CameraPerson/>);

    useEffect(() => {
        dispatch(updateViaCode);
        switch(state.selectedExperience?.subType){
            case "camera.photomode.base":
                if(camera != <CameraPerson/>){
                setCamera(<CameraPerson/>);
                }
                break;
            
            case "camera.aiphotobooth.pix2pix":
                if(camera != <AIPix2Pix/>){

                setCamera(<AIPix2Pix/>);
                }
                break;
            case "camera.aiphotobooth.yearbook":
                if(camera != <Yearbook/>){

                setCamera(<Yearbook/>);
                }
                break;
            
            default:
                if(camera != <CameraPerson/>){

                setCamera(<CameraPerson/>);
                }
                break;
        }
       console.log("Cam");
    }, []);

    return (
        <>
        {camera}
        </>
    );

}