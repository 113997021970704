
import { useSelector, useDispatch } from "react-redux";
import { setExperience } from "../reducers/cartReducer";

export const ExperienceCard = ({ experience,appType="Photobooth", title = "Camera", description="Camera Mode", thumbnail="assets/images/image20.jpg", onOpen=null }) => {
    const dispatch = useDispatch();

    const onOpenClicked = () => {
        dispatch(setExperience(experience));
        if(onOpen != null){
            onOpen(experience);
        }
    }
    return (<div class="embla__slide slider-image item" style={{marginLeft: "1rem", marginRight: "1rem"}}>
    <div class="item-desc">
        <p class="card-desc mbr-fonts-style display-4">
            <strong>{appType}</strong></p>
    </div>
    <div class="slide-content">
        <div class="item-content">
            <h4 class="item-title mbr-fonts-style display-5">{title}</h4>
            <p class="mbr-text mbr-fonts-style display-7">
                {description}</p>
        </div>
        <div class="item-wrapper">
            <div class="item-img">
                <div class="mbr-section-btn"><a class="btn btn-primary display-4" onClick={onOpenClicked}>
                        <span class="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                        Open</a></div>
                <div class="image-wrap">
                    <img src={thumbnail} alt=""/>
                </div>
            </div>
        </div>
    </div>
</div>);
}
