import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import {codeMap ,getCode} from '../../../codes';
import { useSelector, useDispatch } from "react-redux";
import { addItem, deleteItem, setCode, setCodeObj, reset } from "../../../reducers/cartReducer";
import {updateViaCode} from '../../../utils/api';
import {useEffect} from 'react';

import axios from "axios";
export  const ShowAppHomeButtons = ({openCMS,downloadPCApp }) => {


  useEffect(() => {
    // Update the document title using the browser API
  }, []);


    return(

      <div className="containerPaper marginBottom">
      
<section data-bs-version="5.1" class="form1 cid-tU1alDDVqO Width100" id="aform1-1">
    
<div class="container transparentBackground formContainerBig">
    <div class="row justify-content-center">
        <div class="col-12 mbr-form" data-form-type="formoid">
<form class="mbr-form form-with-styler" data-form-title="codeEntry" onSubmit={(e) =>{e.preventDefault();}} >
<div class="row">

</div>



<div class="dragArea row">
<div class="col-lg-12 col-md-12 col-sm-12">
<h1 class="mbr-fonts-style display-5">Select App</h1>
</div>



<div class="col-lg-12 col-md-12 col-sm-12">
<hr/>
</div>

<div class="col-auto buttonHolder Width100 ">
<button type="button" class="w-100 w-100 btn btn-primary display-7 transparentBackground rightMarginSpaced topMarginSpaced"  onClick={openCMS}>CMS</button>
<button type="button" class="w-100 w-100 btn btn-primary display-7 transparentBackground rightMarginSpaced topMarginSpaced"  onClick={downloadPCApp}>Download Mac App</button>
</div>



</div>


</form>
</div>
    </div>
</div>
</section>

      </div>


    ) 
    ;
}