import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    Input,
    Checkbox,
    Textarea ,
    Button,
    Typography,
    Select, Option 
  } from "@material-tailwind/react";
  import LoadingIcons from 'react-loading-icons'
  import {StyleTTS2} from './StyleTTS2';
  import {Vits} from './Vits';
  import {Cortiri} from './Cortiri';
  import {XTTS}  from './XTTS';

import axios from 'axios';
export const TTSGeneration = ({recievedWav, recievedMP3}) =>{

    const [models, setModels] = React.useState([ 
    
    <Option value={"StyleTTS2"} selected>StyleTTS2</Option>,
    <Option value={"VITS"}>VITS</Option>,
    <Option value={"XTTS"}>XTTS</Option>,
    <Option value={"Cortiri"}>Cortiri</Option>, 
  ]);

    const [model, setModel] = React.useState("XTTS");
    const [prompt, setPrompt] = React.useState("");
    const [negativeprompt, setNegativePrompt] = React.useState("Ugly, blurry, low quality");
    const [seed, setSeed] = React.useState(42);
    const [steps, setSteps] = React.useState(20);
    const [width, setWidth] = React.useState(512);
    const [num_images_per_prompt, setnum_images_per_prompt] = React.useState(4);
    const [height, setHeight] = React.useState(512);
    const [images, setImages] = React.useState([]);
    const [mode, setMode] = React.useState("input");
    const Divider = () => {
      return (
          <hr
              className="Divider"
          ></hr>
      );
  };

  const ModelChanged = (e) => {


  }
    const StartImageProcessing = async () => {
      setMode("loading");
      let appended = "";
      if(model.includes( "wavymulder/Analog-Diffusion")){
        appended = "analog style ";
      }
      const imgsper = parseFloat(num_images_per_prompt);
      if(imgsper > 8){
        alert("Max images per prompt is 8");
    setMode("input");

        return;
      }
    const outReq = {
        "prompt": appended+ prompt,
        "negative_prompt": negativeprompt,
        "seed": parseFloat(seed),
        "steps": parseFloat(steps),
        "width":parseFloat(width) ,
        "height": parseFloat(height),
        "num_images_per_prompt": parseFloat(num_images_per_prompt),
        "model": model
    }
    if(outReq.width % 8 != 0 || outReq.height % 8 != 0){
alert("Width and height must be divisible by 8");
setMode("input");

return;
    }

    localStorage.setItem("imageGenReq", JSON.stringify(outReq));
    const resp = await axios.post("https://bravomedianyc--imagegen-stablediff-multiple-1-5-generateface.modal.run", outReq,    {
        'Access-Control-Allow-Origin': '*'
    });
    const resspData = JSON.parse( resp.data.body);
    recievedWav(resspData);
    setMode("input");

    }

    useEffect(() => {

      const req = localStorage.getItem("imageGenReq");
      if(req != null){
        const reqObj = JSON.parse(req);
        setPrompt(reqObj.prompt);
        setNegativePrompt(reqObj.negative_prompt);
        setSeed(reqObj.seed);
        setSteps(reqObj.steps);
        setWidth(reqObj.width);
        setHeight(reqObj.height);
        setnum_images_per_prompt(reqObj.num_images_per_prompt);
        setModel(reqObj.model);
      }
    }
    ,[]);

    return (
         <Card  className="w-auto" color="white" shadow={true} >
      <div className="p-10">
<Typography variant="h4" color="blue-gray">
          Text To Speech
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Utilize AI To Generate Speech From Text
        </Typography>

          <div className="mb-1 flex flex-col gap-6 pt-3">
          <Typography variant="h6" color="blue-gray" className="-mb-3">
              Model
            </Typography>
          <Select variant="outlined" id="showSelect" label="Model" onChange={(e) => {setModel(e)}} value={model}>
                  {models}
                </Select>
                </div>
        <div className="mt-3">
        {
        model == "StyleTTS2"? <StyleTTS2 NewRecieved={recievedWav}/> : 
        model == "VITS"? <Vits NewRecieved={recievedWav}/> : 
        model == "Cortiri"? <Cortiri NewRecieved={recievedWav}/> : 
        model == "XTTS"? <XTTS NewRecieved={recievedWav}/> : 
        
        
        undefined }
        </div>
</div>

</Card>
    )


}