// import { ADD_ITEM, DELETE_ITEM, SET_CODE, SET_OPTIN,SET_CODEOBJ,SET_OPTINS,SET_IMAGESSHOT,SET_MESSAGEBOX,CLOSE_MESSAGEBOX, SET_MESSAGEBOXMESSAGE,SET_MEDIAITEMS,SET_PHOTOBOOTHSETTINGSMODE, SET_EXPERIENCE,SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE, SET_IMAGEREQ, RESET, SET_PHOTOBOOTHSETTINGS } from "../actionTypes/actionTypes";
import { ImageRequestBase } from "../actionTypes/imageRequest";
import { UAIMessageBox } from "../actionTypes/messageBox";
import { AISettings } from "../actionTypes/aiSettings";
import { MediaItems } from "../actionTypes/mediaItems";
import { CMSState } from "../actionTypes/cmsState";
import { OptIns, SetOptIn } from "../actionTypes/optins";
import {app} from "../utils/firebaseHandler";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import { doc, setDoc } from "firebase/firestore"; 

import axios from "axios";

import { PhotoboothSettings } from "../actionTypes/photoboothSettings";

export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const SET_CODE = "SET_CODE";
export const SET_CODEOBJ = "SET_CODEOBJ";
export const SET_EXPERIENCE = "SET_EXPERIENCE";
export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";
export const SET_IMAGEREQ = "SET_IMAGEREQ";
export const SET_PHOTOBOOTHSETTINGS = "SET_PHOTOBOOTHSETTINGS";
export const SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE = "SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE";
export const SET_PHOTOBOOTHSETTINGSMODE = "SET_PHOTOBOOTHSETTINGSMODE";
export const SET_MEDIAITEMS = "SET_MEDIAITEMS";
export const SET_MESSAGEBOX = "SET_MESSAGEBOX";
export const SET_OPTINS = "SET_OPTINS";
export const SET_OPTIN = "SET_OPTIN";
export const SET_MESSAGEBOXMESSAGE = "SET_MESSAGEBOXMESSAGE";
export const SET_IMAGESTODOWNLOAD = "SET_IMAGESTODOWNLOAD";
export const SET_IMAGESSHOT = "SET_IMAGESSHOT";
export const SET_CMSSTATE = "SET_CMSSTATE";
export const SET_CMSSELECTEDINDEX = "SET_CMSSELECTEDINDEX";
export const SET_SELECTEDPLAYLISTITEM = "SET_SELECTEDPLAYLISTITEM";
export const SET_AISETTINGS = "SET_AISETTINGS";
export const CLOSE_MESSAGEBOX = "CLOSE_MESSAGEBOX";
export const RESET = "RESET";



const initialState = {
  numOfItems: 0,
  code:"",
  codeObject:{},
  selectedExperience:{},
  selectedPlaylistItem:{},
  cmsState: new CMSState(),
  imageReq: new ImageRequestBase(),
  mediaItems: new MediaItems(),
  imagesToDownload: [],
  photoboothSettings: new PhotoboothSettings(),
  uaiMessageBox: new UAIMessageBox(),
  optIns: new OptIns(),
  aiSettings: new AISettings(),

};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AISETTINGS:
      state.aiSettings = action.payload.aiSettings;
      return state;
    case ADD_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems + 1,
      };

    case DELETE_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems - 1,
      };

      

    case SET_CMSSTATE:
      return {
        ...state,
        cmsState: action.payload.cmsState,
      };

    case SET_CODE:
      return {
        ...state,
        code: action.payload.code,
      };

    case SET_CODEOBJ:
      return {
        ...state,
        codeObject: action.payload.codeObject,
      };

    case SET_EXPERIENCE:
      return {
        ...state,
        selectedExperience: action.payload.experience,
      };

    case UPDATE_EXPERIENCE:
      const newCodeObject = state.codeObject;
      for(let i = 0; i < newCodeObject.experiences.length; i++){
        if(newCodeObject.experiences[i].name == action.payload.experience.name){
          newCodeObject.experiences[i] = state.selectedExperience;
        }
      }
      state.selectedExperience = action.payload.experience;
      state.codeObject = newCodeObject;
      const db = getFirestore(app);
      setDoc(doc(db, "xperiences", state.codeObject.id), newCodeObject).then((response) => {
        console.log(response);
      return state;

      }
      ).catch((err) => {
        console.error(err);
        return state;
      });
      return state;

      



    case SET_IMAGEREQ:
      return {
        ...state,
        imageReq: action.payload.imageReq,
      };

    case SET_PHOTOBOOTHSETTINGS:
      return {
        ...state,
        photoboothSettings: action.payload.photoboothSettings,
      };

    case SET_MEDIAITEMS:
      return {
        ...state,
        mediaItems: action.payload.mediaItems,
      };


    case SET_MESSAGEBOX:
      return {
        ...state,
        uaiMessageBox: action.payload.uaiMessageBox,
      };

    case SET_IMAGESSHOT:
      state.photoboothSettings.imagesShot = action.payload.imagesShot;
      return state;


    case SET_MESSAGEBOXMESSAGE:
      state.uaiMessageBox.title = action.payload.title;
      state.uaiMessageBox.body = action.payload.body;
      state.uaiMessageBox.open = true;
      return state;


    case CLOSE_MESSAGEBOX:
      state.uaiMessageBox.open = false;
      return state;

    case SET_CMSSELECTEDINDEX:
      state.cmsState.selectedSceneIndex = action.payload.selectedSceneIndex;
      return state;


    case SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE:
      state.photoboothSettings.selectedStyle = action.payload.selectedStyle;
      return state;


    case SET_PHOTOBOOTHSETTINGSMODE:
      state.photoboothSettings.mode = action.payload.mode;
      return state;


    case SET_IMAGESTODOWNLOAD:
      state.imagesToDownload= action.payload.imagesToDownload;
      return state;

    case SET_OPTINS:
      state.optIns = action.payload.optIns;
      return state;


    case SET_OPTIN:
      state.optIns.optInTarget = action.payload.optIn;
      state.optIns.optInTargetValue = action.payload.value;
      state.optIns = SetOptIn(state.optIns);

      return state;

    case RESET:
      state = initialState;
      return state;


    default:
      return state;
  }
};


export const addItem = () => {
  return {
    type: ADD_ITEM,
  };
};

export const deleteItem = () => {
  return {
    type: DELETE_ITEM,
  };
};

export const setCMSState = (cmsState) => {

  return {
    type: SET_CMSSTATE,
    payload: {
      cmsState,
    },
  };
} 

export const setCMSSelectedIndex = (selectedSceneIndex) => {

  return {
    type: SET_CMSSELECTEDINDEX,
    payload: {
      selectedSceneIndex,
    },
  };
} 


export const setCode = (code) => {

  return {
    type: SET_CODE,
    payload: {
      code,
    },
  };
} 


export const setAISettings = (aiSettings) => {

  return {
    type: SET_AISETTINGS,
    payload: {
      aiSettings,
    },
  };
} 
export const setImagesToDownload = (imagesToDownload) => {

  return {
    type: SET_IMAGESTODOWNLOAD,
    payload: {
      imagesToDownload,
    },
  };
} 

export const setOptIn = (optIns) => {
  
    return {
      type: SET_OPTINS,
      payload: {
        optIns,
      },
    };
  }

  export const setOptInTarget = (optIn,value) => {
  
    return {
      type: SET_OPTIN,
      payload: {
        optIn,value
      },
    };
  }
  export const setCodeObj = (codeObject) => {
  
    return {
      type: SET_CODEOBJ,
      payload: {
        codeObject,
      },
    };
  }

  export const setExperience = (experience) => {
    
      return {
        type: SET_EXPERIENCE,
        payload: {
          experience,
        },
      };
    }

  export const updateExperience = (experience) => {
    
      return {
        type: UPDATE_EXPERIENCE,
        payload: {
          experience,
        },
      };
    }


    export const setImagesShot = (imagesShot) => {
    
      return {
        type: SET_IMAGESSHOT,
        payload: {
          imagesShot,
        },
      };
    }


    export const setImagereq = (imageReq) => {
    
      return {
        type: SET_IMAGEREQ,
        payload: {
          imageReq,
        },
      };
    }

    export const setPhotoboothSettings = (photoboothSettings) => {
    
      return {
        type: SET_PHOTOBOOTHSETTINGS,
        payload: {
          photoboothSettings,
        },
      };
    }
    export const setMediaItems = (mediaItems) => {
    
      return {
        type: SET_MEDIAITEMS,
        payload: {
          mediaItems,
        },
      };
    }
    export const setMessageBox = (uaiMessageBox) => {
    
      return {
        type: SET_MESSAGEBOX,
        payload: {
          uaiMessageBox,
        },
      };
    }
    export const setMessageBoxMessage = (title, body) => {
    
      return {
        type: SET_MESSAGEBOXMESSAGE,
        payload: {
          title,
          body,
        },
      };
    }

    export const closeMessageBox = () => {
    
      return {
        type: CLOSE_MESSAGEBOX,
        payload: {
          
        },
      };
    }

    export const setPhotoboothSettingsSelectedStyle = (selectedStyle) => {
    
      return {
        type: SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE,
        payload: {
          selectedStyle,
        },
      };
    }


    export const setPhotoboothSettingsMode = (mode) => {
    
      return {
        type: SET_PHOTOBOOTHSETTINGSMODE,
        payload: {
          mode,
        },
      };
    }


    export const reset = () => {
    
      return {
        type: RESET
              };
    }


    