import React from "react";
import Webcam from "react-webcam";
import { ImageHolder } from "./ImageHolder";
import CameraDevices from '../cameraDevices';
import {isMobile} from 'react-device-detect';
import { useSelector, useDispatch } from "react-redux";
import { setPhotoboothSettingsMode, setMessageBoxMessage, setImagesShot } from "../../../../reducers/cartReducer";
import {ImageSettingsForm} from "../imageSettingsForm";
import InfoDialog from "../../../../ui/infoDialog";
import LoadingIcons from 'react-loading-icons'
export const WebcamCapture = ({setConfirmOpen ,selectedImg,setSelectedImg,imgSrc,setImgSrc,picturesFull, setPicturesFull,setReviewConfirm,setConfirmUploadedOpen,onResults,isLoading, imageCount = 3}) => {
    const webcamRef = React.useRef(null);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const [isMobile_, setisMobile_] = React.useState(false);
  const [reviewHelpShown, setReviewHelpShown] = React.useState(false);
  const [selected, setselected] = React.useState("");
  const [overridePhotoCount, setoverridePhotoCount] = React.useState(false);
  const [classNames, setClassNames] = React.useState([]);

  
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [infoTitle, setInfoTitle] = React.useState("Info");
  const [infoBody, setInfoBody] = React.useState("-");
  const [infoAgree, setInfoAgree] = React.useState("Ok");


  const [imageHolders, setImageHolders] = React.useState(
   [<div></div>, <div></div>, <div></div>]);

    // const [imgSrc, setImgSrc] = React.useState([]);
    const [cameraChoice, setCameraChoice] = React.useState("environment");
    const [deviceId, setdeviceId] = React.useState("");
    const [videoConstraints, setVideoConstraints] = React.useState({
      facingMode: "environment" 
    });
    const resetConfirmed = async () => {
      console.log("resetConfirmed");
      setPicturesFull(false);
      setConfirmUploadedOpen(false);
      setImgSrc([]);
      dispatch(setImagesShot([]));
      dispatch(setPhotoboothSettingsMode("camera"));
      setoverridePhotoCount(false);

      updateImageHoldersForced(["imageHolder"],[null], imageCount);

  }
    const updateImageHolders = (classes = ["imageHolder", "imageHolder", "imageHolder"], imgs=[]) => {
      if(imageCount != 1){
      if(imgs.length !== 0){
        const imgHolders = [];
        for (let i = 0; i < imageCount; i++) {
          if(imgs[i]){
            imgHolders.push(<ImageHolder index={i} imgSrc={imgs[i] } onClicked={imgClicked} classNme={classes[i] ? classes[i] : "imageHolder"}/>);
        }else{
          imgHolders.push(<ImageHolder index={i} imgSrc={null} onClicked={imgClicked} classNme={classes[i] ? classes[i] : "imageHolder"}/>);
        }
        }
        
        setImageHolders(imgHolders);
      }else{
        
        const imgHolders = [];
        for (let i = 0; i < imageCount; i++) {
          if(classes[i]){
          imgHolders.push(<ImageHolder index={i} imgSrc={imgSrc[i] } onClicked={imgClicked} classNme={classes[i]}/>);
        }else{
          imgHolders.push(<ImageHolder index={i} imgSrc={imgSrc[i]} onClicked={imgClicked} classNme={"imageHolder"}/>);
        }
        }

        setImageHolders(imgHolders);
      }
      
      }else{
        setImageHolders([]);

      }

      
    }
    const updateImageHoldersForced = (classes = ["imageHolder", "imageHolder", "imageHolder"], imgs=[], count) => {
      if(count != 1){
      if(imgs.length !== 0){
        const imgHolders = [];
        for (let i = 0; i < count; i++) {
          if(imgs[i]){
            imgHolders.push(<ImageHolder index={i} imgSrc={imgs[i] } onClicked={imgClicked} classNme={classes[i] ? classes[i] : "imageHolder"}/>);
        }else{
          imgHolders.push(<ImageHolder index={i} imgSrc={null} onClicked={imgClicked} classNme={classes[i] ? classes[i] : "imageHolder"}/>);
        }
        }
        
        setImageHolders(imgHolders);
        return imgHolders;
      }else{
        
        const imgHolders = [];
        for (let i = 0; i < count; i++) {
          if(classes[i]){
          imgHolders.push(<ImageHolder index={i} imgSrc={imgSrc[i] } onClicked={imgClicked} classNme={classes[i]}/>);
        }else{
          imgHolders.push(<ImageHolder index={i} imgSrc={imgSrc[i]} onClicked={imgClicked} classNme={"imageHolder"}/>);
        }
        }

        setImageHolders(imgHolders);
        return imgHolders;

      }
      
      }else{
        setImageHolders([]);
        return [];

      }

      
    }
    const generateClassNames = (selectedIndex = -1) =>{
      const imgClasses = [];
      for(let i = 0; i < imageCount; i++){
        if(selectedIndex == i){
        imgClasses.push("imageHolder-selectedImage");

        }else{
          
        imgClasses.push("imageHolder");
        }
      }

      return imgClasses;
    }
    const resultsRecieved = () => {
      console.log("resultsRecieved");
      if(state.selectedExperience.params[0].presets.overrideShareCount >= 0){
      setoverridePhotoCount(true);
      const imgClasses2 = generateClassNames(state.selectedExperience.params[0].presets.overrideShareCount);
      setClassNames(imgClasses2);
      // const nullimgs = [];
      // for(let i = 0; i < state.selectedExperience.params[0].presets.overrideShareCount; i++){
      //   nullimgs.push(imgSrc[i]? imgSrc[i] : null);
      // }
      updateImageHoldersForced([],state.photoboothSettings.imagesShot, state.selectedExperience.params[0].presets.overrideShareCount);
      console.log("Updated Image Holders");
      console.log(imgClasses2);
      console.log(state.photoboothSettings.imagesShot);
      console.log(state.selectedExperience.params[0].presets.overrideShareCount);

      }
    }

  React.useEffect(
    () => {
      if(state.selectedExperience.params[0].presets.forceCamera != "none"){
        const forcedCamera = state.selectedExperience.params[0].presets.forceCamera;
        if(forcedCamera == "user" || forcedCamera == "environment" && isMobile){
          setCameraChoice(forcedCamera);
          localStorage.setItem("camera",forcedCamera);
          setVideoConstraints({facingMode: forcedCamera });
      }
      else {

        if(forcedCamera == "user" || forcedCamera == "environment" && isMobile == false){
          
        }else{
          setCameraChoice(forcedCamera);
          localStorage.setItem("camera",forcedCamera);
          setVideoConstraints({deviceId: forcedCamera });
        }
      }
      }

      setInfoOpen(false);
      setisMobile_(isMobile);
      onResults.current = resultsRecieved;
      const imgClasses = generateClassNames();
      updateImageHolders(imgClasses);
      console.log("useEffect!!");
    },
    [isMobile_]
  );
    const cameraFlip = () => {

      if(cameraChoice == "environment"){
        setCameraChoice("user");
        localStorage.setItem("camera","user");
        setVideoConstraints({facingMode: "user" });
    }else if(cameraChoice == "user"){
      setCameraChoice("environment");
      localStorage.setItem("camera","environment");
      setVideoConstraints({facingMode:"environment" });
    }
  }
  const setSelectedImage = (key) => {
    const imgindx = key;
    const newClassNames = ["imageHolder", "imageHolder", "imageHolder"];
    const classNames = generateClassNames(imgindx);
    // console.log(newClassNames);
    console.log(      state.selectedExperience.params[0].presets.overrideShareCount >= 0 && state.photoboothSettings.mode == "reviewGenerated");
    if(state.selectedExperience.params[0].presets.overrideShareCount >= 0 && state.photoboothSettings.mode == "reviewGenerated"){
    console.log("overridePhotoCount");

      updateImageHoldersForced(classNames, state.photoboothSettings.imagesShot, state.selectedExperience.params[0].presets.overrideShareCount);
    }else{
    console.log("regularPhotoCount");

    updateImageHolders(classNames, state.photoboothSettings.imagesShot);
    }
    setClassNames(classNames);
  }
  const imgClicked = (key,indx, caller) => {
    setselected(caller);
      setSelectedImg(key);
      setSelectedImage(indx);
      }


  const cameraDeviceChanged = (deviceId) => {

    setdeviceId(deviceId);
    localStorage.setItem("deviceId",deviceId);
    if(isMobile_){
    // alert("deviceId  isMobile_: " + deviceId);

      setVideoConstraints({facingMode: deviceId});
      return;
    }
    setVideoConstraints({deviceId: deviceId });
    // console.log("deviceId : " + deviceId);
  }
    // const [functionButton, setfunctionButton] = React.useState(<button className="shutterButton" onClick={capture}><img src="images/shutter.png"/></button>);
    // const videoConstraints = {
    //   facingMode: { exact: localStorage.getItem("camera","environment") }
    // };
    const acceptClicked = () => {
        // console.log("acceptClicked");
      // setClassNames(["imageHolder", "imageHolder", "imageHolder"]);
      const imgClasses = generateClassNames();
      setClassNames(imgClasses);

        setConfirmOpen(true, "Confirm Selection", "Are you sure you want to select this image?");
        if(state.selectedExperience.params[0].presets.overrideShareCount >0){
        const imgClasses2 = generateClassNames(state.selectedExperience.params[0].presets.overrideShareCount);
        setClassNames(imgClasses2);
      
      // updateImageHoldersForced(imgClasses2,[], state.selectedExperience.params[0].presets.overrideShareCount);
        }
    }

    const download = (url, name = "download", type = "png") =>{
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = name + "." + type;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    const acceptGeneratedClicked = () => {
        console.log("acceptGeneratedClicked");
        download(selectedImg, "generated", "jpg");
        // setConfirmOpen(true, "Confirm Selection", "Are you sure you want to select this image?");
    }
 
    const acceptSaveClicked = () => {
        console.log("acceptGeneratedClicked");
        download(selectedImg, "generated", "jpg");
        // setConfirmOpen(true, "Confirm Selection", "Are you sure you want to select this image?");
    }

    const resetClicked = () => {

      acceptClicked();
    }
 

    const capture = React.useCallback(() => {
      const imageSrc_ = webcamRef.current.getScreenshot();
      if(imgSrc.length < imageCount) {
        console.log("imgSrc.length < imageCount");
        console.log(imgSrc);
        console.log(imgSrc.length);
        const imgs = [...imgSrc, imageSrc_];
        setImgSrc(imgs);
        dispatch(setImagesShot(imgs));

        setSelectedImg(imageSrc_);
        const classes = generateClassNames();

        // updateImageHolders(["imageHolder", "imageHolder", "imageHolder"], [...imgSrc, imageSrc_]);
        updateImageHolders(classes, imgs);
        

      }if(imgSrc.length == imageCount - 1){
        setPicturesFull(true);
        setSelectedImg(imageCount != 1 ? imgSrc[0] : imageSrc_);
        dispatch(setPhotoboothSettingsMode("photoReview"));
        const imgs = [...imgSrc, imageSrc_];
        dispatch(setImagesShot(imgs));

        setImgSrc(imgs);
        dispatch(setImagesShot(imgs));

        console.log(imgs);

        if(reviewHelpShown == false){
          if(imageCount != 1){
          setReviewHelpShown(true);
          setInfoOpen(true);
          setInfoTitle("Review Photo");
          setInfoBody("Please select a photo to upload.");
          updateImageHolders(generateClassNames(0),   [...imgSrc, imageSrc_]);
          console.log("MULTIPLE");

          
        }
        else{
          console.log("SINGLE");
          updateImageHolders(generateClassNames(0), [imgSrc[0]] );

        }
     
        const classes = generateClassNames(0);

        // setfunctionButton(<button className="shutterButton" onClick={acceptClicked}><img src="images/icon_common_49.png"/></button>)

      }
      }

   
    }, [imgSrc]);


    const previewClicked = (key) => {
        }
  
    return (
      <div className="webcamHolder">

        {/* The Images that have been taken */}
         <div className="imageHolder-Container">
        {imageHolders}
        {/* {       ()=>{ 
            console.log("overrideShareCount1");

          if(state.photoboothSettings.mode == "reviewGenerated" && state.selectedExperience.params[0].presets.overrideShareCount >= 0){
            const imgClasses2 = generateClassNames(state.selectedExperience.params[0].presets.overrideShareCount);
            setClassNames(imgClasses2);
            console.log("overrideShareCount");
            return (updateImageHoldersForced(imgClasses2,[], state.selectedExperience.params[0].presets.overrideShareCount));
          } else{
            return imageHolders;
          }
        
    }
        } */}
{/* 
        <ImageHolder index={0} imgSrc={imgSrc[0] } onClicked={imgClicked} classNme={classNames[0]}/>
      <ImageHolder index={1} imgSrc={imgSrc.length >=2 ? imgSrc[1] : null} onClicked={imgClicked} classNme={classNames[1]}/> 
      <ImageHolder index={2} imgSrc={imgSrc.length >=3 ? imgSrc[2] : null} onClicked={imgClicked} classNme={classNames[2]}/> */}
        </div>

        {/* The Image that is selected or the webcam image */}
        {state.photoboothSettings.mode != "camera"  ? <ImageHolder imgSrc={selectedImg}  classNme={"previewImage"} onClicked={previewClicked}/> : <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        className="webcam previewImage_image"
        videoConstraints={videoConstraints} 
        />}


       {/* The Shutter button or the Accept Selected Image button */}
       <div className="buttonHolder">
        
        {
        
        state.photoboothSettings.mode == "loading"   ? <button className="shutterButton"><LoadingIcons.Rings ssName="shutterButton" /></button>:
        state.photoboothSettings.mode == "camera" ||state.photoboothSettings.mode == "all"  ? <button className="shutterButton" onClick={capture}><img  className=" shutterButton-img" src="images/shutter.png"/></button>:
        state.photoboothSettings.mode == "photoReview"? <button className="shutterButton" onClick={resetClicked}><img className=" shutterButton-img" src="images/icon_common_49.png"/></button>:
        state.photoboothSettings.mode == "reviewGenerated" && state.selectedExperience.audience == "public" ? <button className="shutterButton" onClick={acceptGeneratedClicked}><img className=" shutterButton-img" src="images/Icons/icon_app_135.png"/></button>:
        state.photoboothSettings.mode == "reviewGenerated" && state.selectedExperience.audience == "backstage" ? <button className="shutterButton" onClick={acceptSaveClicked}><img className=" shutterButton-img" src="images/Icons/material-symbols_save-rounded.png"/></button>:
        null
        }
        
      
        {/* {imgSrc.length >= 3 ?   <button className="shutterButton" onClick={acceptClicked}><img className=" shutterButton-img" src="images/icon_common_49.png"/></button> : <button className="shutterButton" onClick={capture}><img  className=" shutterButton-img" src="images/shutter.png"/></button>} */}
        {/* <button className="shutterButton" onClick={cameraFlip}><img className=" shutterButton-img" src="images/icons/icon-park-solid_flip-camera.png"/></button>  */}
        
        {
        state.photoboothSettings.mode == "camera" ||state.photoboothSettings.mode == "all" && isMobile?
        <button className="shutterButton" onClick={cameraFlip}><img className=" shutterButton-img" src="images/Icons/icon-park-solid_flip-camera.png"/></button>
        :
        state.photoboothSettings.mode == "camera" ||state.photoboothSettings.mode == "all" && isMobile ==false ? <CameraDevices onCameraSelected={cameraDeviceChanged}/>:null
        }
        {
        state.photoboothSettings.mode == "photoReview"? <button className="shutterButton" onClick={resetConfirmed}><img className=" shutterButton-img" src="images/Icons/Undo.png"/></button>:
null

        }
        
        {/* <ImageSettingsForm/> */}
        </div>
      <InfoDialog open={infoOpen} title={infoTitle} body={infoBody} agree={infoAgree} onClose={()=>{setInfoOpen(false)}}/>

      </div>
    );
  };
  