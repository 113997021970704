import * as React from 'react';

import QRCode from "react-qr-code";
export default function QRGen({value = "hey"}) {
    // const [confirmOpen, setConfirmOpen] = React.useState(false);
    // const [confirmTitle, setConfirmTitle] = React.useState("");
    // const [confirmDescription, setConfirmDescription] = React.useState("");
  return (
    <div>
     <QRCode value={value} />,
      </div>
  );
}