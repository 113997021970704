import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import {codeMap ,getCode} from '../../../codes';
import { useSelector, useDispatch } from "react-redux";
import { addItem, deleteItem, setCode, setCodeObj, reset } from "../../../reducers/cartReducer";
import {updateViaCode, fileNameFromUrl} from '../../../utils/api';
import {useEffect} from 'react';
import ReactPlayer from 'react-player'
import Checkbox from '@mui/material/Checkbox';

import axios from "axios";
export  const SceneItemDetailsBox = ({sceneItem, applyScene}) => {
  const state = useSelector((state) => state);
  const [sceneNumber, setsceneNumber] = React.useState(sceneItem.sceneNumber);
  const [act, setact] = React.useState(sceneItem.act);
  const [sceneID, setsceneID] = React.useState(sceneItem.sceneID);
  const [sceneType, setsceneType] = React.useState(sceneItem.sceneType);
  const [sceneName, setsceneName] = React.useState(sceneItem.sceneName);
  const [url, seturl] = React.useState(sceneItem.url);
  const [path, setpath] = React.useState(sceneItem.path);
  const [autoPlay, setautoPlay] = React.useState(sceneItem.autoplay ? sceneItem.autoplay == 1 ? true :false: false);
  const [loop, setloop] = React.useState(sceneItem.loop ? sceneItem.loop == 1 ? true :false: false);
  const [fade, setfade] = React.useState(sceneItem.fade ? sceneItem.fade:0);
  const [nextScene, setnextScene] = React.useState(sceneItem.nextScene);

  const dispatch = useDispatch();
  const onLoggedIn = () => {
    console.log("onLoggedIn");
  }

  useEffect(() => {
console.log("ItemDetailsBox");
console.log(sceneItem);

setsceneNumber(sceneItem.sceneNumber);
setact(sceneItem.act);
setsceneID(sceneItem.sceneID);
setsceneType(sceneItem.sceneType);
setsceneName(sceneItem.sceneName);
seturl(sceneItem.url);
setpath(sceneItem.path);
setnextScene(sceneItem.nextScene);
if(sceneItem.fade != null){
setfade(sceneItem.fade);
}
if(sceneItem.autoplay != null){
setautoPlay(sceneItem.autoplay == 1 ? true :false);
}
if(sceneItem.loop != null){
  setloop(sceneItem.loop == 1 ? true :false);
}

    // Update the document title using the browser API
  }, [sceneItem]);


  const handleClick = () => {
    const code = document.getElementById("code-aform1-1").value.toUpperCase();
    console.log("handleClick");
    dispatch(setCode(code));
    dispatch(updateViaCode);
    
    // onLoggedIn();
}

const applySceneData = () => {

  sceneItem.sceneNumber = sceneNumber;
  sceneItem.act = act;
  sceneItem.sceneID = sceneID;
  sceneItem.sceneType = sceneType;
  sceneItem.sceneName = sceneName;
  sceneItem.url = url;
  sceneItem.path = path;
  sceneItem.nextScene = nextScene;
  sceneItem.fade = fade;
  sceneItem.autoplay = autoPlay == true ? 1 : 0;
  sceneItem.loop = loop == true ? 1 : 0;
  applyScene(sceneItem);

}

const applyURL = (e) =>{
const newURL = e.target.value.replace("dl=0", "raw=1");
var filename = fileNameFromUrl(newURL)

seturl(newURL);
if(filename != null){
  if(path.includes(filename) == false){
setpath(filename);
}
}
}
const  submitHandler=(e) =>{
  e.preventDefault();
  handleClick();
}

    return(

      <div className="containerPaper ">
      
<section data-bs-version="5.1" className="form1 cid-tU1alDDVqO Width100" id="aform1-1">

    
    


    
<div className="container transparentBackground formContainerBig">
    <div className="row justify-content-center">
        <div className="col-12 mbr-form" data-form-type="formoid">
<form className="mbr-form form-with-styler" data-form-title="codeEntry"  onSubmit={submitHandler}>
<div className="row">

</div>



<div className="col-lg-12 col-md-12 col-sm-12 scene-form-item ">
<h1 className="mbr-fonts-style display-5">Scene Info</h1>
</div>

<div className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group multi-input-group" data-for="Element">

<div data-for="text6" className="col-lg-4 col-md-4 col-sm-4 form-group formItem leftAlightText">
<label for="text3-formbuilder-0" className="form-control-label mbr-fonts-style display-7">Act</label>
<input type="text" name="text3" defaultValue={act}  value={act} onChange={(e)=>{setact(e.target.value)}}data-form-field="text3" className="form-control display-7" id="text3-formbuilder-0"/>
</div>


<div data-for="text6" className="col-lg-4 col-md-4 col-sm-4 form-group formItem leftAlightText">
<label for="text6-formbuilder-0" className="form-control-label mbr-fonts-style display-7">Scene #</label>
<input type="text" name="text6" defaultValue={sceneNumber}  value={sceneNumber} onChange={(e)=>{setsceneNumber(e.target.value)}}data-form-field="text6" className="form-control display-7"id="text6-formbuilder-0"/>
</div>

</div>


<div className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group multi-input-group" data-for="Element">

<div data-for="text6" className="col-lg-4 col-md-4 col-sm-4 form-group formItem leftAlightText">
<label for="text3-formbuilder-0" className="form-control-label mbr-fonts-style display-7">Scene ID</label>
<input type="text" name="text3" defaultValue={sceneID}  value={sceneID} onChange={(e)=>{setsceneID(e.target.value)}}data-form-field="text3" className="form-control display-7" id="text3-formbuilder-0"/>
</div>

<div data-for="Scene Type" className="col-lg-4 col-md-4 col-sm-4 form-group formItem leftAlightText">
<label for="Scene Type-formbuilder-0" className="form-control-label mbr-fonts-style display-7">Type</label>
<select name="Scene Type" data-form-field="Scene Type" className="form-control form-select display-7" id="Scene Type-formbuilder-0" defaultValue={sceneItem.sceneType}>
<option value={0}>Video</option>
<option value={1}>Web</option>
</select>
</div>

</div>




<div data-for="text3" className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group">

</div>
<div data-for="text4" className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group leftAlightText">
<label for="text4-formbuilder-0" className="form-control-label mbr-fonts-style display-7">Scene Name</label>
<input type="text" name="text4" defaultValue={sceneName} value={sceneName} onChange={(e)=>{setsceneName(e.target.value)}} data-form-field="text4" className="form-control display-7" id="text4-formbuilder-0"/>
</div>



<div className="col-lg-12 col-md-12 col-sm-12 scene-form-item ">
<hr/>
</div>

<div className="col-lg-12 col-md-12 col-sm-12 scene-form-item">
<h1 className="mbr-fonts-style display-5">Media Settings</h1>
</div>


<div data-for="text2" className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group  leftAlightText">
<label for="text2-formbuilder-0" className="form-control-label mbr-fonts-style display-7">{"URL (HEVC not compatible)" }</label>
<input type="text" name="text2"  defaultValue={url} value={url} onChange={applyURL} data-form-field="text2" className="form-control display-7" id="text2-formbuilder-0"/>
</div>



<div data-for="text2" className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group leftAlightText">
<label for="text2-formbuilder-0" className="form-control-label mbr-fonts-style display-7">Save Path</label>
<input type="text" name="text2"  defaultValue={path}value={path} onChange={(e)=>{setpath(e.target.value)}} data-form-field="text2" className="form-control display-7" id="text2-formbuilder-0"/>
</div>

<div className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group leftAlightText" data-for="text5">
<label for="text5-formbuilder-0" className="form-control-label mbr-fonts-style display-7">Next Scene</label>
<input type="text" name="text5"  defaultValue={nextScene != "" ? nextScene : ""}  placeholder={nextScene == "" ? "Scene###" : ""} data-form-field="text5" className="form-control display-7" id="text5-formbuilder-0"/>
</div>

<div data-for="text2" className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group leftAlightText">
<label for="text2-formbuilder-0" className="form-control-label mbr-fonts-style display-7">Fade</label>
<input type="number" step="0.01"  name="fade"  defaultValue={fade}value={fade} onChange={(e)=>{setfade(e.target.value)}} data-form-field="text2" className="form-control display-7" id="text2-formbuilder-0"/>
</div>

<div data-for="text2" className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group  leftAlightText">
<label for="text2-formbuilder-0" className="form-control-label mbr-fonts-style display-7">AutoPlay</label>
<Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} defaultChecked={autoPlay} />
</div>

<div data-for="text2" className="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group  leftAlightText">
<label for="text2-formbuilder-0" className="form-control-label mbr-fonts-style display-7">Loop</label>
<Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} defaultChecked={loop} />
</div>


<div className="col-lg-12 col-md-12 col-sm-12 scene-form-item ">
<hr/>
</div>

<div className="col-lg-12 col-md-12 col-sm-12 scene-form-item ">
<ReactPlayer className="widthFitParent " url={url} controls={true} />
</div>



<div className="col-lg-12 col-md-12 col-sm-12 scene-form-item ">
<hr/>
</div>




<div className="col-auto buttonHolder">
<button type="button" className="w-100 w-100 btn btn-primary display-7 transparentBackground rightMarginSpaced topMarginSpaced"  onClick={applySceneData}>Apply</button>
</div>


</form>
</div>
    </div>
</div>
</section>

      </div>


    ) 
    ;
}