import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    Input,
    Checkbox,
    Textarea ,
    Button,
    Typography,
    Select, Option 
  } from "@material-tailwind/react";
  import LoadingIcons from 'react-loading-icons'
  import {uuidv4} from '../../../utils/api';
import axios from 'axios';
export const StyleTTS2 = ({NewRecieved}) =>{

    const [models, setModels] = React.useState([ 
    
    <Option value={"Lady"}>Lady</Option>,
  ]);
  
    const [model, setModel] = React.useState("Lady");
    const [prompt, setPrompt] = React.useState("");
    const [steps, setSteps] = React.useState(20);
    const [mode, setMode] = React.useState("input");
    const Divider = () => {
      return (
          <hr
              className="Divider"
          ></hr>
      );
  };
    const RunProcess = async () => {
      setMode("loading");
      let appended = "";
      if(model.includes( "wavymulder/Analog-Diffusion")){
        appended = "analog style ";
      }
    const outReq = {
        "text": appended+ prompt,
        "steps": parseFloat(steps),
    }
    if(outReq.steps > 30){
alert("Quality any greater than 30 steps is not noticably better, and will take much longer to process.");
setMode("input");
return;
    }

    localStorage.setItem("ttsReq", JSON.stringify(outReq));
    const resp = await axios.post("https://bravomedianyc--texttovoice-styletts2-generateface.modal.run", outReq,    {
        'Access-Control-Allow-Origin': '*'
    });
    // const resspData = JSON.parse( resp.data.body);
    const outResponse = {
        "media": resp.data.body,
        "prompt": prompt,
        "steps": steps,
        "model": "StyleTTS2",
        "gender": "Feminine",
        "speaker": model,
        "date": new Date().toLocaleString(),
        "id": uuidv4()
    }
    NewRecieved(outResponse);
    setMode("input");

    }

    useEffect(() => {

      const req = localStorage.getItem("imageGenReq");
      if(req != null){
        const reqObj = JSON.parse(req);
        setPrompt(reqObj.prompt);
        setSteps(reqObj.steps);
        setModel(reqObj.model);
      }
    }
    ,[]);

    return (
         <Card  className="w-auto" color="white" shadow={true} >
      <div className="p-10">
        <Typography color="black" className="mt-1 text-sm font-bold">
          StyleTTS2
        </Typography>
        <Typography color="gray" className="mt-1 text-xs">
          A model that can be trained off off any voice. The fastest model we have that is not Siri sounding. We currently only have 1 voice, but will be adding more soon.
        </Typography>
        
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-1 flex flex-col gap-6">

          <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Voice
            </Typography>

          <Select variant="outlined" id="showSelect" label="Model" onChange={(e) => {setModel(e)}}>
                  {models}
                </Select>

            
                <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Text
            </Typography>
            <Textarea label="Text" 
            value={prompt}
              onChange={(e) => setPrompt(e.target.value)}  />
          
                <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Quality Steps
            </Typography>
            <Input
              size="lg"
              placeholder="42"
              containerProps={{ className: "min-w-[72px]" }}

              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}    
              type="number"
              value={steps}
              onChange={(e) => setSteps(e.target.value)}
            />
          <Divider/>

  


          </div>
          {mode == "loading"?  <Button className="mt-6" fullWidth disabled={true} >
            Loading
          </Button> :  <Button className="mt-6" fullWidth onClick={RunProcess}>
            Generate
          </Button>}
        </form>
</div>

</Card>
    )


}