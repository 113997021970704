import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    Input,
    Checkbox,
    Textarea ,
    Button,
    Typography,
    Slider,
    Select, Option 
  } from "@material-tailwind/react";
  import LoadingIcons from 'react-loading-icons'

import axios from 'axios';
const lerp = (a, b, n) => {
  return (1 - n) * a + n * b;
};
const modelsMap = 
  [
    {
      "name": "Aesthetic",
      "model": "playgroundai/playground-v2-1024px-aesthetic",
      "guidanceRange": [0.0, 6],
      "maxImagesPerPrompt": 1
    },
  
    {
      "name": "Realistic",
      "model": "SG161222/RealVisXL_V2.0",
      "guidanceRange": [0.0, 6],
      "maxImagesPerPrompt": 1
    },
  
    {
      "name": "Epic Realistic",
      "model": "digiplay/Photon_v1",
      "guidanceRange": [0.0, 6],
      "maxImagesPerPrompt": 8
    },
  
    {
      "name": "Animagine",
      "model": "Linaqruf/animagine-xl-2.0",
      "guidanceRange": [0.0, 13],
      "maxImagesPerPrompt": 1
    },
  
    {
      "name": "SD Turbo",
      "model": "stabilityai/sdxl-turbo",
      "guidanceRange": [0.0, 0],
      "maxImagesPerPrompt": 1
    },
  
  
    {
      "name": "Dream Shaper",
      "model": "dreamshaper-8",
      "guidanceRange": [0.0, 6],
      "maxImagesPerPrompt": 8
    },
  
  ]

export const ImageGeneration = ({NewRecieved}) =>{
    const [modelMap, setModelMap] = React.useState(modelsMap[0]);
    const [models, setModels] = React.useState([ 
      <Option value={"playgroundai/playground-v2-1024px-aesthetic"}>Aesthetic</Option>,
    <Option value={"SG161222/RealVisXL_V2.0"}>Realistic</Option>,
    <Option value={"digiplay/Photon_v1"}>Epic Realistic</Option>,
    <Option value={"Linaqruf/animagine-xl-2.0"}>Animagine</Option>,
    <Option value={"stabilityai/sdxl-turbo"}>SD Turbo</Option>,
    <Option value={"Lykon/dreamshaper-8"}>Dream Shaper</Option>,
    // <Option value={"prompthero/openjourney-v4"}>Open Journey</Option>,

    // <Option value={"hakurei/waifu-diffusion"}>Anime Waifu</Option>, 
    // <Option value={"dreamlike-art/dreamlike-photoreal-2.0"}>Dreamlike Photoreal</Option>, 
    // <Option value={"SG161222/Realistic_Vision_V4.0_noVAE"}>Realistic Vision</Option>, 
    // <Option value={"Yntec/AnalogMadness4"}>Analog Madness</Option>, 
    // <Option value={"wavymulder/Analog-Diffusion"}>Analog Diffusion</Option>, 
    // <Option value={"Yntec/epiCPhotoGasm"}>Epic Photogasm </Option>, 
    // <Option value={"ogkalu/Comic-Diffusion"}>Comic book</Option>, 
    // <Option value={"Linaqruf/anything-v3.0"}>Anything 3.0</Option>
  ]);
    const [resolutions, setResolutions] = React.useState([ 
      <Option value={[512,512]}>Square: 512x512</Option>,
      <Option value={[1024,1024]}>Square: 1024x1024</Option>,
      <Option value={[1080,1080]}>Square: 1080x1080</Option>,
 
      <Option value={[640,360]}>16:9 Landscape: 640x360</Option>,
      <Option value={[1280,720]}>16:9 Landscape: 1280x720</Option>,
      <Option value={[1920,1080]}>16:9 Landscape: 1920x1080</Option>,
           
      <Option value={[360,640]}>16:9 Portrait: 360x640</Option>,
      <Option value={[720,280]}>16:9 Portrait: 720x280</Option>,
      <Option value={[1080,1920]}>16:9 Portrait: 1080x1920</Option>,

      // Create 4:3 resolutions

      <Option value={[640,480]}>4:3 Landscape: 640x480</Option>,
      <Option value={[800,600]}>4:3 Landscape: 800x600</Option>,
      <Option value={[1024,768]}>4:3 Landscape: 1024x768</Option>,
      <Option value={[1280,960]}>4:3 Landscape: 1280x960</Option>,
      <Option value={[1440,1080]}>4:3 Landscape: 1440x1080</Option>,

      <Option value={[480,640]}>4:3 Portrait: 480x640</Option>,
      <Option value={[600,800]}>4:3 Portrait: 600x800</Option>,
      <Option value={[768,1024]}>4:3 Portrait: 768x1024</Option>,
      <Option value={[960,1280]}>4:3 Portrait: 960x1280</Option>,
      <Option value={[1080,1440]}>4:3 Portrait: 1080x1440</Option>,
      
      
      
      
  ]);
  const fileInput = React.useRef(null);
  const [inputImageFile, setInputImageFile] = React.useState(null);
  const [inputImageURL, setInputImageURL] = React.useState(null);
  const [guidanceSlider, setGuidanceSlider] = React.useState(50);
    const [model, setModel] = React.useState("SG161222/RealVisXL_V2.0");
    const [prompt, setPrompt] = React.useState("");
    const [negativeprompt, setNegativePrompt] = React.useState("Ugly, blurry, low quality");
    const [seed, setSeed] = React.useState(42);
    const [steps, setSteps] = React.useState(20);
    const [width, setWidth] = React.useState(512);
    const [num_images_per_prompt, setnum_images_per_prompt] = React.useState(4);
    const [height, setHeight] = React.useState(512);
    const [guidanceScale, setGuidanceScale] = React.useState(0.8);
    const [upscale, setUpscale] = React.useState(1);
    const [onlyCenterFace, setOnlyCenterFace] = React.useState(false);
    const [faceFixWeight, setFaceFixWeight] = React.useState(0.6);
    const [faceFixVersion, setFaceFixVersion] = React.useState("1.3");
    const [useFaceFix, setuseFaceFix] = React.useState(0);
    const [images, setImages] = React.useState([]);
    const [mode, setMode] = React.useState("input");
    const Divider = () => {
      return (
          <hr
              className="Divider"
          ></hr>
      );
  };
    const StartImageProcessing = async () => {
      setMode("loading");
      let appended = "";
      if(model.includes( "wavymulder/Analog-Diffusion")){
        appended = "analog style ";
      }
      const imgsper = parseFloat(num_images_per_prompt);
      if(imgsper > 8){
        alert("Max images per prompt is 8");
    setMode("input");

        return;
      }
    let outReq = {
        "prompt": appended+ prompt,
        "negative_prompt": negativeprompt,
        "seed": parseFloat(seed),
        "steps": parseFloat(steps),
        "width":parseFloat(width) ,
        "height": parseFloat(height),
        "guidance_scale": parseFloat(guidanceScale),
        "num_images_per_prompt": parseFloat(num_images_per_prompt),
        "model": model,
        "faceFix":useFaceFix,
      "version" :faceFixVersion,
      "upscale" :upscale,
      "bg_upsampler" :"realesrgan",
      "bg_tile" :400,
      "suffix" :"None",
      "only_center_face" :onlyCenterFace,
      "aligned" :false,
      "ext" :"auto",
      "weight" :faceFixWeight
    }
    if(model == "stabilityai/sdxl-turbo"){
      outReq.steps = 1;
      outReq.guidanceScale = 0.0;
    }
    if(outReq.width % 8 != 0 || outReq.height % 8 != 0){
alert("Width and height must be divisible by 8");
setMode("input");

return;
    }

    localStorage.setItem("imageGenReq", JSON.stringify(outReq));
    const resp = await axios.post("https://bravomedianyc--imagegen-stablediff-multiple-1-5-generateface.modal.run", outReq,    {
        'Access-Control-Allow-Origin': '*'
    });
    const resspData = JSON.parse( resp.data.body);
    NewRecieved(resspData);
    setMode("input");

    }

    useEffect(() => {

      const req = localStorage.getItem("imageGenReq");
      if(req != null){
        const reqObj = JSON.parse(req);
        setPrompt(reqObj.prompt);
        setNegativePrompt(reqObj.negative_prompt);
        setSeed(reqObj.seed);
        setSteps(reqObj.steps);
        setWidth(reqObj.width);
        setHeight(reqObj.height);
        setnum_images_per_prompt(reqObj.num_images_per_prompt);
        setModel(reqObj.model);
      }
    }
    ,[]);

    return (
         <Card  className="w-auto" color="white" shadow={true} >
      <div className="p-10">
<Typography variant="h4" color="blue-gray">
          Stable V
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Generate images using text
        </Typography>


        
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-1 flex flex-col gap-6">

          <Typography variant="h6" color="blue-gray" className="-mb-3">
              Model
            </Typography>

          <Select variant="outlined" id="showSelect" label="Model" onChange={(e) => {
            const match = modelsMap.find((model) => model.model == e);
            setModelMap(match);
            setModel(e)
            setGuidanceScale(lerp(match.guidanceRange[0], match.guidanceRange[1], guidanceSlider / 100));
            if(num_images_per_prompt > match.maxImagesPerPrompt){
              setnum_images_per_prompt(match.maxImagesPerPrompt);
            }}}>
                  {models}
                </Select>

            
                <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Prompt
            </Typography>
            <Textarea label="Prompt" 
            value={prompt}
              onChange={(e) => setPrompt(e.target.value)}  />
          
                <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Negative Prompt
            </Typography>
            <Textarea label="Negative Prompt" 
            value={negativeprompt}
              onChange={(e) => setNegativePrompt(e.target.value)}  />
          
          <Divider/>

          <div className="InputItems">
          <div className="InputItem">
            
          <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Images To Generate
            </Typography>
            <Input
              size="sm"
              containerProps={{ className: "min-w-[72px] max-w-30p" }}
              placeholder="4"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}    
              type="number"
              value={num_images_per_prompt}
              onChange={(e) => setnum_images_per_prompt(e.target.value)}
            />
          </div>


            <div className="InputItem">
          <Typography         variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Seed
            </Typography>
            <Input
              size="sm"
              containerProps={{ className: "min-w-[72px] max-w-30p" }}
              placeholder="42"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}    
              type="number"
              value={seed}
              onChange={(e) => setSeed(e.target.value)}
            />
          </div>

          <div className="InputItem">
            
          <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Steps
            </Typography>
            <Input
              size="lg"
              placeholder="42"
              containerProps={{ className: "min-w-[72px] max-w-30p" }}

              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}    
              type="number"
              value={model != "stabilityai/sdxl-turbo" ? steps : 1}
              onChange={(e) => setSteps(e.target.value)}
              disabled={model == "stabilityai/sdxl-turbo"}

            />
          </div>

          


          </div>
<Divider/>
  
<Typography variant="h6" color="blue-gray" className="-mb-3">
              Resolution
            </Typography>

          <Select variant="outlined" id="showSelect" label="Resolution" onChange={(e) => {
              setWidth(e[0]);
              setHeight(e[1]);

          }}>
                  {resolutions}
                </Select>
                
            
          <div className="InputItem">
            
            <Typography variant="h6" color="blue-gray" className="my-auto headerInput">
                Guidance Scale
              </Typography>
              <div className="my-auto grid h-full">
              <Slider size="lg" defaultValue={90} className="!my-auto"  onChange={(e) => {
                console.log(e.target.value);
                setGuidanceSlider(e.target.value);
                // lerp between modelMap.guidanceRange[0] and modelMap.guidanceRange[1] with the value of e.target.value
                // and set the guidance scale to that value
              
                const val = lerp(modelMap.guidanceRange[0], modelMap.guidanceRange[1], e.target.value / 100);
                console.log(val);
                setGuidanceScale(val);
              }
              }
              />
            </div>

          </div>
          <Divider/>

          <div className="InputItem !flex mx-auto">

          <Typography variant="h6" color="blue-gray" className=" headerInput my-auto">
              Face Fix
            </Typography>
            <Checkbox color="indigo" className="my-auto" onChange={(e) =>{

              if(e.target.checked){
                setuseFaceFix(1);
            }else{
              setuseFaceFix(0);
            }
          
          }} />
            </div>

            <div className={useFaceFix == true? '': 'hidden'}>

            <div className="flex mx-auto justify-center">
          <Typography         variant="h6" color="blue-gray" className="-mb-3 headerInput w-fit  my-auto">
          Only Center Face
            </Typography>
            <Checkbox color="indigo" className="my-auto" onChange={(e) =>{
if(e.target.checked){
  setOnlyCenterFace(true);
}else{
  setOnlyCenterFace(false);
}

}} />
          </div>


          <div className="inline">

            <Typography variant="h6" color="blue-gray" className="mb-3 headerInput">
              Version
            </Typography>

          <Select variant="outlined" id="showSelect" label="Version" onChange={(e) => {setFaceFixVersion(e)}}>
    <Option value={"RestoreFormer"}>Best Quality</Option>, 
          <Option value={"1.3"}>High Fidelity</Option>,
    <Option value={"1.4"}>Quick</Option>, 

    <Option value={"1.2"}>Airbrushed</Option>,
                </Select>
                </div>




          <div className="InputItems">
          <div className="InputItem   w-1/2 mx-auto pt-3">
          <Typography variant="h6" color="blue-gray" className="my-auto headerInput">
              Weight
            </Typography>
            <Input
              size="sm"
              containerProps={{ className: "min-w-[72px] max-w-30p" }}
              placeholder="1.5"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}    
              type="number"
              value={faceFixWeight}
              onChange={(e) => setFaceFixWeight(e.target.value)}
            />
          </div>

          <div className="InputItem  w-1/2  mx-auto pt-3">
            
            <Typography variant="h6" color="blue-gray" className="my-auto headerInput">
                Upscale Mult
              </Typography>
              <Input
                size="lg"
                placeholder="1"
                containerProps={{ className: "min-w-[72px] max-w-30p" }}
  
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}    
                type="number"
                value={upscale}
                onChange={(e) => setUpscale(e.target.value)}
              />
            </div>


        
   
          </div>
          </div>
<Divider/>


          </div>
          {mode == "loading"?  <Button className="mt-6" fullWidth disabled={true} >
            Loading
          </Button> :  <Button className="mt-6" fullWidth onClick={StartImageProcessing}>
            Generate
          </Button>}
         
        </form>
</div>

</Card>
    )


}