import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Box, Container } from '@mui/system';
import CameraScene from '../components/cameraScene';
import {WebcamCapture} from '../components/cam/WebcamCapture';
import ConfirmDialog from "../../../ui/confirmDialog";
import { Pix2PixAugmentImage} from "../../../utils/aiPhotoboothAPI";
import {ImageRequestBase} from "../../../actionTypes/imageRequest";
import { setPhotoboothSettings, setMediaItems, setPhotoboothSettingsMode, setMessageBoxMessage, setImagesShot } from "../../../reducers/cartReducer";
import InfoDialog from "../../../ui/infoDialog";
import QRGen from '../components/cam/qr/qRGen';
export default function AIPix2Pix() {
  const state = useSelector((state) => state);
const dispatch = useDispatch();

    const [scene, setScene] = React.useState("");
    const onResults = React.useRef(null)

    // const [re, setScene] = React.useState("");
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [reviewHelpShown, setReviewHelpShown] = React.useState(false);
    const [confirmTitle, setConfirmTitle] = React.useState("");
    const [confirmDescription, setConfirmDescription] = React.useState("");
    const [confirmUploadedOpen, setConfirmUploadedOpen] = React.useState(false);
    const [confirmUploadedTitle, setConfirmUploadedTitle] = React.useState("");
    const [confirmUploadedDescription, setConfirmUploadedDescription] = React.useState("");
    const [selectedImg, setSelectedImg] = React.useState(0);
    const [imgSrc,setImgSrc] = React.useState([]);

    const [picturesFull, setPicturesFull] = React.useState(false);
    const [qrCode, setQrCode] = React.useState("Hello");
    const [appCode, setAppCode] = React.useState(null);

    const [firstRunMessageShown, setfirstRunMessageShown] = React.useState(false);
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [infoTitle, setInfoTitle] = React.useState("Info");
    const [infoBody, setInfoBody] = React.useState("-");
    const [infoAgree, setInfoAgree] = React.useState("Ok");


    const syncStyle = (style) => {
        state.photoboothSettings.imageRequest.prompt = style.prompt;
        state.photoboothSettings.imageRequest.negativeprompt = style.negprompt;
        state.photoboothSettings.imageRequest.style = style.style;
        state.photoboothSettings.imageRequest.model = style.model;
        try{
            state.photoboothSettings.imageRequest.seed = style.seeds[0];
        }catch{

        }
        try{
            state.photoboothSettings.imageRequest.controlnet_scale = style.controlnet_conditioning_scales[state.photoboothSettings.controlnet_conditioning_scales];
        }catch{
            state.photoboothSettings.imageRequest.controlnet_scale = style.controlnet_conditioning_scales.normal;

        }
        state.photoboothSettings.selectedStyle = style;
        dispatch(setPhotoboothSettings(state.photoboothSettings));
       }
       const showMessage = (title, message) => {
        setInfoOpen(true);
        setInfoTitle(title);
        setInfoBody(message);
    }
    useEffect(() => {
        // Update the document title using the browser API
        // state.selectedExperience.params[0].presets.styles[0]
       const scene_ =  state.code? "camera" : "codeLogin";
        dispatch(setPhotoboothSettingsMode("camera"));
       const code = state.code;
    //    if(state.photoboothSettings.selectedStyle == {}){
        const style = state.selectedExperience.params[0].presets.styles[0];
        syncStyle(style);

        if(firstRunMessageShown == false){
            showMessage("Welcome", "This is the AI Pix2Pix Photobooth. Take three photos and process them with AI!");
            setfirstRunMessageShown(true);
        }


    //    }



    // console.log(exp);

    if(code != null){
        setAppCode(code);
    }
       setScene(scene_);
      }, []);


    const acceptConfirmed = async () => {
        console.log("acceptConfirmed");
        
        setConfirmOpen(false);
        const req = state.photoboothSettings.imageRequest;
        dispatch(setPhotoboothSettingsMode("loading"));
        
        const resp = await Pix2PixAugmentImage(selectedImg.replace("data:image/jpeg;base64,","", ), req.style, req.prompt, req.negativeprompt, req.model, req.subModel, req.seed, req.steps, 3, req.controlnet_scale, req.guidance_scale, req.lowThreshold, req.highThreshold, req.rotationx, req.rotationy, req.rotationz, req.scalex, req.scaley, req.scalez);
        console.log("RESPONSE");
        console.log(resp);
        

        if(resp.status == 200){
            dispatch(setMediaItems(resp.data));
            let mediaItems = resp.data;
            let mediaItems_ = [];
            mediaItems.media.forEach((mediaItem) => {
                mediaItems_.push("data:image/png;base64,"+mediaItem.media);
            });
            
     
        dispatch(setPhotoboothSettingsMode("reviewGenerated"));
        if(reviewHelpShown == false){
            setReviewHelpShown(true);
            showMessage("Review Photo", "Please select a photo to share.");
          }
          dispatch(setImagesShot(mediaItems_));
            setImgSrc(mediaItems_);
            setSelectedImg(mediaItems_[0]);
            onResults.current();
        // setConfirmUploadedOpen(true);
        // setConfirmUploadedTitle("Image Uploaded");
        // setConfirmUploadedDescription("Your image has been uploaded successfully. Do you want to continue taking pictures or go back to the home screen?");
        }

    }
    
    
    const resetConfirmed = async () => {
        console.log("resetConfirmed");
        setPicturesFull(false);
        setConfirmUploadedOpen(false);
        setImgSrc([]);
        dispatch(setPhotoboothSettingsMode("camera"));

    }
    
    const disagreedConfirmed = () => {
        console.log("disagreedConfirmed");
        setConfirmOpen(false);
    }
    
    const homeConfirmed = () => {
        console.log("homeConfirmed");
        setConfirmUploadedOpen(false);
        setScene("home");
    }
    
    const setConfirmOpenWebcam = (state=false,title="", description="") => {
        setConfirmTitle(title);
        setConfirmDescription(description);
        console.log("acceptConfirmed");
        setConfirmOpen(state);
    }

    const onQRScan = (data) => {
        console.log("onQRScan");
        console.log(data);
            setQrCode(data);
        setScene("came1ra");
    }

    const onCodeLoggedIn = () => {
setScene("camera");
    }
    
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        {/* <TopNav className="topNavBar"/> */}

      {scene == "qrView"? 
      <QRGen value={qrCode}  /> : 
      scene == "camera"? 
      <WebcamCapture setConfirmOpen={setConfirmOpenWebcam} selectedImg={selectedImg} setSelectedImg={setSelectedImg} imgSrc={imgSrc} setImgSrc={setImgSrc} picturesFull={picturesFull} setPicturesFull={setPicturesFull} setConfirmUploadedOpen={setConfirmUploadedOpen} imageCount={state.selectedExperience.params[0].presets.imageCount} overrideShare={state.selectedExperience.params[0].presets.overrideShareCount} onResults={onResults }/>:

    //   <CameraScene setConfirmOpenWebcam={setConfirmOpenWebcam}  selectedImg={selectedImg} setSelectedImg={setSelectedImg}  imgSrc={imgSrc} setImgSrc={setImgSrc}picturesFull={picturesFull} setPicturesFull={setPicturesFull} imageCount={state.selectedExperience.params[0].presets.imageCount} setConfirmUploadedOpen={setConfirmUploadedOpen} overrideShare={3} /> : 

      null
      
      }

      <ConfirmDialog open={confirmOpen} onClose={() => {setConfirmOpen(false)}} onAgree={acceptConfirmed} onDisagree={disagreedConfirmed} title={confirmTitle} description={confirmDescription}/>
      <ConfirmDialog open={confirmUploadedOpen} onClose={() => {setConfirmUploadedOpen(false)}} onAgree={resetConfirmed} onDisagree={homeConfirmed} title={confirmUploadedTitle} description={confirmUploadedDescription} agree="Continue" disagree='Home'/>
    
      <InfoDialog open={infoOpen} title={infoTitle} body={infoBody} agree={infoAgree} onClose={()=>{setInfoOpen(false)}}/>
      </Container>
    </React.Fragment>
  );
}