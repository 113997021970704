import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function StandardImageList({itemData=[], onItemClicked= null}) {
  return (
    <ImageList sx={{ width: window.innerWidth * 0.8, height: window.height * 0.8 }} cols={4} rowHeight={window.height * 0.3}>
      {itemData != [] ? itemData.map((item, index) => (
        <ImageListItem key={item.img}>
          <img
            // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item}`}
            alt={index}
            loading="lazy"
            onClick={()=>{onItemClicked(item);}}
            className='ClickableMedia'
          />
        </ImageListItem>
      ) ): null}
    </ImageList>
  );
}


