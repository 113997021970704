import * as React from 'react';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import {  setCode, setCodeObj, setExperience } from "../reducers/cartReducer";
import HelpDialog from "./helpDialog";
import {isMobile} from 'react-device-detect';
import {
    Navbar,
    MobileNav,
    Typography,
    Button,
    IconButton,
  } from "@material-tailwind/react";
 
  
export default function TopNav() {
  const [appCodeObject, setAppCodeObject] = React.useState(null);
  const [appCode, setAppCode] = React.useState(null);
  const [openNav, setOpenNav] = React.useState(false);

  const dispatch = useDispatch();

  const logOut = () => {
    localStorage.removeItem("code");
    localStorage.removeItem("codeObject");
    dispatch(setCode(""));
    dispatch(setCodeObj(null));
    dispatch(setExperience(null));
    window.location = "/";
  }
  useEffect(() => {
    const code = localStorage.getItem("code");
    const codeobj= JSON.parse( localStorage.getItem("codeObject"));
    // console.log(exp);
    window.addEventListener(
        "resize",
        () => window.innerWidth >= 960 && setOpenNav(false),
    );
    
    if(window.location.href.indexOf("logout") > -1)
    {
        logOut();
    }
    if(code != null){
        setAppCode(code);
        setAppCodeObject(codeobj);
    }
  }, []);

  return (
    <>
    <div className='navBar transparentBackground'>
    <img src = "bravoIcon.png" className="navBar-logo" onClick={()=>{window.location = "/"}}/>
    <div className="navButtons">
      <button className="navButtons-Logout" onClick={()=>{window.location = "/logout"}}>Log Out</button>
</div>
</div>
<Outlet />

</>
  );
}
export  function TopNavNotLoggedIn() {
  const [appCodeObject, setAppCodeObject] = React.useState(null);
  const [appCode, setAppCode] = React.useState(null);
  const logOut = () => {
    localStorage.removeItem("code");
    localStorage.removeItem("codeObject");
    window.location = "/";
  }
  useEffect(() => {
    const code = localStorage.getItem("code");
    const codeobj= JSON.parse( localStorage.getItem("codeObject"));
    // console.log(exp);

    if(code != null){
        setAppCode(code);
        setAppCodeObject(codeobj);
    }
  }, []);

  return (
    <>
<section data-bs-version="5.1" class="menu menu1 deepm5 cid-txhiN0nK3c Width100 stickyTop transparentBackground" once="menu" id="menu1-0">
<nav class="navbar navbar-dropdown navbar-expand-lg transparentBackground">
    <div class="menu_box container">
        <div class="navbar-brand d-flex d-lg-none">
            <span class="navbar-logo">
                <a href="/">
                    <img src="assets/images/uai-logo-app-100x100.png" alt=""/>
                </a>
            </span>
            <span class="navbar-caption-wrap"><a class="navbar-caption display-4" href="/">{appCodeObject != null ? appCodeObject.name : ""}</a></span>
        </div>
        {isMobile == true ? 
        <button onClick={()=>{ window.location = "/"}} class="navButton" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <img className=" shutterButton-img homeImage" src="images/Icons/ButtonTop.png"/>
        </button>
        :null
}
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="navbar-brand d-none d-lg-flex">
            <span class="navbar-logo">
                <a href="/">
                    <img src="assets/images/uai-logo-app-100x100.png" alt=""/>
                </a>
            </span>
                <span class="navbar-caption-wrap"><a class="navbar-caption display-4" href="/">{appCodeObject != null ? appCodeObject.name : ""}</a></span>
            </div>
            <ul class="navbar-nav nav-dropdown transparentBackground" data-app-modern-menu="true"><li class="nav-item"><a class="nav-link link text-primary display-4" href="/"><strong>HOME</strong></a></li>
        </ul>
        </div>
    </div>
</nav>
</section>
<Outlet />
    </>
  );
}



export  function TopNavSelector() {
    const state = useSelector((state) => state);
  
    const [navBar, setNavBar] = React.useState( <TopNavNotLoggedIn/>);
    const [code, setCode] = React.useState();
    useEffect(() => {
      const code_ = state.code;
      // const code_ = localStorage.getItem("code");
      if(code_ != null && code_ != "" && code_ != "code" ){
        setNavBar(<TopNav/>);
        setCode(code_);
      }
    }, []);
  
    return (
      <>
  {navBar}
  <HelpDialog/>
      </>
    );
  }
  