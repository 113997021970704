import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import {codeMap ,getCode} from '../../../codes';
import { useSelector, useDispatch } from "react-redux";
import { addItem, deleteItem, setCode, setCodeObj, setCMSSelectedIndex ,setCMSState} from "../../../reducers/cartReducer";
import {updateViaCode} from '../../../utils/api';
import {updateExperience} from '../../../reducers/cartReducer';

import {useEffect} from 'react';
import {VideoScenePlaylistHeaderBox} from './VideoScenePlaylistHeaderBox';
import {SceneItemDetailsBox} from './SceneItemDetailsBox';
import {CustomTabPanel} from '../common/CustomTabPanel';
import {VideoSceneDataParams} from './ExperienceItem';
import axios from "axios";

export  const VideoItemsCMS = () => {
  const state = useSelector((state) => state);
  const [showName, setshowName] = React.useState(state.selectedExperience.params[0].presets.showPlaylist.showName);
  const [version, setversion] = React.useState(state.selectedExperience.params[0].presets.showPlaylist.version);
  const [playlist, setplaylist] = React.useState(state.selectedExperience.params[0].presets.showPlaylist.playlist);
  const [selectedScene, setselectedScene] = React.useState(null);
  const [value, setvalue] = React.useState(0);
  const [applyMode, setapplyMode] = React.useState('update');
  const [selectedSceneIndex, setselectedSceneIndex] = React.useState(0);
  const applyNewDropDown = React.useRef(null)

  const dispatch = useDispatch();
  const onLoggedIn = () => {
    console.log("onLoggedIn");
  }


  useEffect(() => {
    const newCMSState = state.cmsState;
    newCMSState.selectedCMSItem = state.selectedExperience.params[0].presets.showPlaylist;
    
    dispatch(setCMSState(newCMSState));
    // Update the document title using the browser API
  }, []);

  const applySceneData = (e) => {
    const newExperience = state.selectedExperience;
    console.log("SELECTED SCENE INDEX");
    console.log(state.cmsState.selectedSceneIndex);
    newExperience.params[0].presets.showPlaylist.playlist[state.cmsState.selectedSceneIndex] = e;
    const currentVersion = parseFloat(version);
    const newVersion = 0.01 + currentVersion;
    setversion(newVersion.toString());
  newExperience.params[0].presets.showPlaylist.version = newVersion.toString();
    dispatch(updateExperience(newExperience));
    applyNewDropDown.current();
  }

  const setSelectedPlaylistChanged = (e) => {
    let indx = 0;
    try{
     indx = e.target.value;
    }catch{
      indx = e;
    }
    setselectedSceneIndex(indx);
    console.log(indx);
    console.log(playlist[indx]);
    const newCMSState = state.cmsState;
    setapplyMode("update");

    newCMSState.selectedPlaylistItem = playlist[indx];
    dispatch(setCMSState(newCMSState));
    dispatch(setCMSSelectedIndex(indx));
    setselectedScene(<SceneItemDetailsBox sceneItem={newCMSState.selectedPlaylistItem} applyScene={applySceneData} selectedMode={"update"}/>);
  }


  const addNewPlaylist = (scene, playlist_) => {
    const newCMSState = state.cmsState;
    setapplyMode("add");
    newCMSState.selectedPlaylistItem = scene;
    dispatch(setCMSState(newCMSState));
    dispatch(setCMSSelectedIndex(playlist_.length - 1));
    setselectedScene(<SceneItemDetailsBox sceneItem={newCMSState.selectedPlaylistItem} applyScene={applySceneData} selectedMode={"add"}/>);
  }

  const addNewScene = (scene, playlist_) => {
    addNewPlaylist(scene, playlist_);
  }

console.log(state);

  const handleClick = () => {
    const code = document.getElementById("code-aform1-1").value.toUpperCase();
    console.log("handleClick");
    dispatch(setCode(code));
    dispatch(updateViaCode);
    
    // onLoggedIn();
}

const  submitHandler=(e) =>{
  e.preventDefault();
  handleClick();
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const handleChange = (event, newValue) => {
  setvalue(newValue);
};

    return(

      <>
<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab  className='whiteText' label="Scenes" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <VideoScenePlaylistHeaderBox applyNewDropDown={applyNewDropDown} setselectedScene={setSelectedPlaylistChanged}  setapplyMode={setapplyMode} addNewScene={addNewScene} />
      {selectedScene!== null ? selectedScene : null }
      
      </CustomTabPanel>
    </Box>

     

      </>


    ) 
    ;
}