import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from "react-redux";
import { setMessageBox, setOptInTarget } from "../reducers/cartReducer";


export  function ShowMessage(title = "Info", body = "Body text", agree = "Ok") {
const state = useSelector((state) => state);
  const dispatch   = useDispatch();

  let msgbox = state.uaiMessageBox;
  msgbox.open = true;
  msgbox.title = title;
  msgbox.body = body;
  msgbox.agree = agree;
  dispatch(setMessageBox(msgbox));


}


export default function InfoDialog({open, onClose, title, body, agree}) {
  const closeWindow = ()=>{
    onClose();
  }
  
  const oncheckboxChanged = (state)=>{

  }


  return (
    <div>
      <Dialog
        open={open}
        // open={state.uaiMessageBox.open}
        onClose={closeWindow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
        {/* <FormControlLabel control={<Checkbox onChange={oncheckboxChanged} />} label="Don't show again." /> */}

        </DialogContent>

        <DialogActions>

          <Button onClick={closeWindow} autoFocus>
            {agree}
          </Button>
        </DialogActions>
      </Dialog> 
  </div>

  );
}