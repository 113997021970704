import * as React from 'react';

import {codeMap ,getCode} from '../codes';
import {isMobile} from 'react-device-detect';
import { useResizeDetector } from 'react-resize-detector';
import { useSelector, useDispatch } from "react-redux";
import { setMessageBoxMessage, setExperience } from "../reducers/cartReducer";
import { getxperienceByCode } from '../utils/firebaseHandler';
import {ExperienceCard } from './experienceCard';
import {useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {updateViaCode} from '../utils/api';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export  const Experiences = () => {
  const state = useSelector((state) => state);

  const [experiences, setExperiences] = React.useState(null);
  const { width, height, ref } = useResizeDetector();
  const [scrollClass, setScrollClass] = React.useState("" );
  const experienceOpened = (experience) => {
    console.log("experienceOpened");
    console.log(experience);
    dispatch(setExperience(experience));
    localStorage.setItem("experience",JSON.stringify(experience));
    window.location = experience.url;
  }
  const dispatch = useDispatch();
  useEffect( () => {
    dispatch(updateViaCode);
    const code = state.code;
    // const code = localStorage.getItem("code");
    localStorage.setItem("showFirstMessage", false);

    const getCodeProxy = async (code) => {
    const codeobj_ =  await getCode(code);
    //const codeobj_ =  await getxperienceByCode(code);
    console.log(codeobj_);
    // if(state.optIns.experiencesPage == false){
    //   dispatch(setMessageBoxMessage("EXPERIENCES", "Select your expereinces here."));
    // }

    if(code != null){
      console.log(codeobj_);
    const exp = codeobj_.experiences.map((experience)=>{return <ExperienceCard experience={experience} appType={experience.appType} title={experience.title} description={experience.description} thumbnail={experience.thumbnail} onOpen={experienceOpened}/>});

      localStorage.setItem("codeObject",JSON.stringify(codeobj_));
        setExperiences(exp);
    }
  }
  if(code != null && code != ""&& code != "code"){
    getCodeProxy(code);

  }
    // if(width < 891){
    //   setScrollClass("scrollx" );
    // }
  }, []);

  const onLoggedIn = () => {
    console.log("onLoggedIn");
  }
  const onLoggedIn_ = () => {
    console.log("onLoggedIn");
  }
  

// console.log(codeobj.experiences);

    return(

      <Paper elevation={3} className='paperArea2' >
      <div className='experienceHolder transparentBackground'>
      

      <section data-bs-version="5.1" className="slider01 mbr-embla agentm5 cid-tU1h14CpR4 transparentBackground" id="slider01-2">
    
    

    <div className="container-fluid transparentBackground ">
        <div className="row transparentBackground">
            <div className="col-12 col-lg-3 card Width100">
                <div className="desc-wrapper">
                    <p className="mbr-desc mbr-fonts-style display-4">
                        Your XPeriences</p>
                </div>
            </div>
            <div className="col-12 col-lg-9 card">
                
            </div>
            <div className="col-12 card">
                <div className="embla embcontainer" data-skip-snaps="true" data-align="center" data-contain-scroll="trimSnaps" data-loop="true" data-auto-play-interval="5" data-draggable="true">
                    <div className="embla__viewport">
                        <div ref={ref} class={"embla__container " }>
                        <Stack direction="row" spacing={2}>

                            {/* { codeobj != null ?  codeobj.experiences.map((experience)=>{ return <ExperienceCard appType={experience.appType} title={experience.title} description={experience.description} thumbnail={experience.thumbnail} onOpen={onLoggedIn}/>}) : null */}
                            { experiences}
                            </Stack>
                        </div>
                    </div>
                    {/* <button className="embla__button embla__button--prev">
                        <span className="mbr-iconfont mobi-mbri-left mobi-mbri" aria-hidden="true"></span>
                        <span className="sr-only visually-hidden visually-hidden visually-hidden">Previous</span>
                    </button>
                    <button className="embla__button embla__button--next">
                        <span className="mbr-iconfont mobi-mbri-right mobi-mbri" aria-hidden="true"></span>
                        <span className="sr-only visually-hidden visually-hidden visually-hidden">Next</span>
                    </button> */}
                </div>
            </div>
        </div>
    </div>
</section>
      </div>
      </Paper>


    ) 
    ;
  };
  