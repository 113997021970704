

// import React, useDispatch, useSelector and useState

import * as React from 'react';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { saveAs } from 'file-saver';
import {downloadMedia} from '../../../../utils/api';
import axios from 'axios';
// import {imageDataURI} from 'image-data-uri';

export const MediaItemView = ({ onClose,removeImage, img = "",}) => {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    
      const handleClose = () => {
        onClose(false);
      };
      const download = (url, name = "download", type = "png") =>{
        // imageDataURI
        // .encodeFromURL(url)
        // .then(uri => {
  
        //   const link = document.createElement("a");
        //   link.href = uri;
        //   link.download = `${name}.${type}`;
        //   document.body.appendChild(link);
        //   link.click();
        // })
        // .catch(() => {
        //   console.log ("Download error");
        // });



      }
      const downloadMediaClicked = () => {
        downloadMedia(img, (url_) => { }, (err) => { });
        
      };

      const deleteMediaClicked = () => {
        removeImage(img);
      };

   
    useEffect(() => {
       console.log("Cam");
    }, []);

    return (
        <div className='MediItemView_Container'>
        <div className='MediItemView_Container_Img_Container ClickableMedia'>
        <img className='MediItemView_Container_Img ClickableMedia' src={img}/>
        </div>
        <div className="buttonHolder">
        <button className="shutterButton-smaller" onClick={deleteMediaClicked}><img  className=" shutterButton-img-smaller" src="images/Icons/trash_can.png"/></button>
        <button className="shutterButton-smaller" onClick={downloadMediaClicked}><img  className=" shutterButton-img-smaller" src="images/Icons/ButtonTop-2.png"/></button>

        </div>
        <button className="closeButton" onClick={handleClose}><img  className=" shutterButton-img-smaller" src="images/Icons/CancelButton.png"/></button>


       
        </div>
    );

}