import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import {codeMap ,getCode} from '../../../codes';
import { useSelector, useDispatch } from "react-redux";
import { addItem, deleteItem, setCode, setCodeObj, reset } from "../../../reducers/cartReducer";
import {updateViaCode} from '../../../utils/api';
import {useEffect} from 'react';
import {updateExperience} from '../../../reducers/cartReducer';
import {VideoSceneDataParams} from './ExperienceItem';

import axios from "axios";
export  const VideoScenePlaylistHeaderBox = ({setselectedScene,addNewScene, setapplyMode, applyNewDropDown}) => {
  const state = useSelector((state) => state);
  const [showName, setshowName] = React.useState(state.selectedExperience.params[0].presets.showPlaylist.showName);
  const [version, setversion] = React.useState(state.selectedExperience.params[0].presets.showPlaylist.version);
  const [playlist, setplaylist] = React.useState(state.selectedExperience.params[0].presets.showPlaylist.playlist);
  const [playlisthtml, setplaylisthtml] = React.useState(state.selectedExperience.params[0].presets.showPlaylist.playlist.map((item, index) => {  return (<option value={index}>{item.sceneName}</option>)}));
   const [updateChanged, setupdateChanged] = React.useState(false);
  const dispatch = useDispatch();
  const onLoggedIn = () => {
    console.log("onLoggedIn");
  }

const UpdateDropDown = () => {
  const newHTML = playlist.map((item, index) => {  return (<option value={index}>{item.sceneName}</option>)});
  setplaylisthtml(newHTML);
}
  useEffect(() => {
    applyNewDropDown.current = UpdateDropDown;
    // Update the document title using the browser API
  }, [applyNewDropDown.current]);

  const sceneScelector = () =>{
  return document.getElementById("Playlist-formbuilder-0");
    
  }
  const applyPlaylistData = () => {
    const newExperience = state.selectedExperience;
    newExperience.params[0].presets.showPlaylist.showName = showName;
    if(updateChanged == false){
      const currentVersion = parseFloat(version);
      const newVersion = 0.01 + currentVersion;
      setversion(newVersion.toString());
    newExperience.params[0].presets.showPlaylist.version = newVersion.toString();
    }else{
    newExperience.params[0].presets.showPlaylist.version = version;
    }
    dispatch(updateExperience(newExperience));
    setupdateChanged(false);

  }

  const handleClick = () => {
    const code = document.getElementById("code-aform1-1").value.toUpperCase();
    console.log("handleClick");
    dispatch(setCode(code));
    dispatch(updateViaCode);
    
    // onLoggedIn();
}
const addScene = () => {

  const newScene = new VideoSceneDataParams();
  playlist.push(newScene);
  setplaylist(playlist);
  const newHTML = playlist.map((item, index) => {  return (<option value={index}>{item.sceneName}</option>)});
  setplaylisthtml(newHTML);
  sceneScelector().value = playlist.length - 1;

  addNewScene(newScene, playlist);
}
const nextVersion = () => {
  const currentVersion = parseFloat(version);
  // check how many decimal places there are in the current version
  // const decimalPlaces = currentVersion.toString().split(".")[1].length || 0;
  //incriment the current version by 1 and decimal places
  // const newVersion = (1.0 *(1.0/ Math.pow(10, decimalPlaces))) + currentVersion;
  const newVersion = 0.01 + currentVersion;
  setupdateChanged(true);
  setversion(newVersion.toString());
}

const removeScene = () =>{
  const val = sceneScelector();
  //remove scene by index from playlist
  playlist.splice(val.value, 1);
  setplaylist(playlist);
  const newHTML = playlist.map((item, index) => {  return (<option value={index}>{item.sceneName}</option>)});
  setplaylisthtml(newHTML);
}

const  submitHandler=(e) =>{
  e.preventDefault();
  handleClick();
}
const openVideoScene = () => {
  const val = sceneScelector();
  console.log(playlist[val.value]);
  setselectedScene(val.value);
}

    return(

      <div className="containerPaper marginBottom">
      
<section data-bs-version="5.1" class="form1 cid-tU1alDDVqO Width100" id="aform1-1">
    
<div class="container transparentBackground formContainerBig">
    <div class="row justify-content-center">
        <div class="col-12 mbr-form" data-form-type="formoid">
<form class="mbr-form form-with-styler" data-form-title="codeEntry" onSubmit={(e) =>{e.preventDefault();}} >
<div class="row">

</div>



<div class="dragArea row">
<div class="col-lg-12 col-md-12 col-sm-12 scene-form-item">
<h1 class="mbr-fonts-style display-5">Edit Show Scenes</h1>
</div>

<div class="col-lg-12 col-md-12 col-sm-12 scene-form-item form-group multi-input-group" data-for="Element">

<div class="col-lg-4 col-md-4 col-sm-4 form-group leftAlightText" data-for="Show Name">
<div class="inputLabel">
<label for="Show Name-formbuilder-0" class="form-control-label mbr-fonts-style display-7">Show Name</label>
</div>
<input type="text" name="Show Name" defaultValue={showName} value={showName} onChange={(e)=>{setshowName(e.target.value);}} data-form-field="Show Name" class="form-control display-7" id="Show Name-formbuilder-0"/>
</div>
<div data-for="text" class="col-lg-4 col-md-4 col-sm-4 form-group leftAlightText">
<div class="inputLabel">

<label for="Playlist-formbuilder-0" class="form-control-label mbr-fonts-style display-7">Version</label>
<div class="buttonHolder">
  <button class="bluebordered uaiButton squareButton rightMarginSpaced leftMarginSpaced bottomMarginSpaced radiusSoft" onClick={nextVersion}>+</button>

</div>
</div>
<input type="text" name="text" defaultValue={version}  value={version} onChange={(e)=>{setversion(e.target.value); setupdateChanged(true);}}  data-form-field="text" class="form-control display-7"  id="text-formbuilder-0"/>
</div>
</div>
<div class="col-lg-12 col-md-12 col-sm-12 scene-form-item">
<hr/>
</div>
<div class="col-lg-12 col-md-12 col-sm-12 scene-form-item dropDownBar">

<div data-for="Playlist" class="col-lg-4 col-md-4 col-sm-4 form-group leftAlightText">
<div class="inputLabel">

<label for="Playlist-formbuilder-0" class="form-control-label mbr-fonts-style display-7">Scenes</label>
<div class="buttonHolder">
  <button class="bluebordered uaiButton squareButton rightMarginSpaced leftMarginSpaced bottomMarginSpaced radiusSoft" onClick={removeScene}>-</button>
  <button class="bluebordered uaiButton squareButton rightMarginSpaced leftMarginSpaced bottomMarginSpaced radiusSoft" onClick={addScene}>+</button>
  <button class="bluebordered uaiButton shButton rightMarginSpaced leftMarginSpaced bottomMarginSpaced radiusSoft buttonPadding" onClick={openVideoScene}>OPEN</button>

</div>
</div>
<select name="Playlist" data-form-field="Playlist" class="form-control form-select display-7" id="Playlist-formbuilder-0" onChange={(e) =>{setselectedScene(e)}}>
{playlisthtml}
{/* {playlist? playlist.map((item, index) => {
  return <option value={index}>{item.sceneName}</option>

} ) : null} */}

</select>

</div>

</div>

<div class="col-lg-12 col-md-12 col-sm-12 scene-form-item">
<hr/>
</div>

<div class="col-auto buttonHolder Width100 ">
<button type="button" class="w-100 w-100 btn btn-primary display-7 transparentBackground rightMarginSpaced topMarginSpaced"  onClick={applyPlaylistData}>Update Show</button>
</div>



</div>


</form>
</div>
    </div>
</div>
</section>

      </div>


    ) 
    ;
}