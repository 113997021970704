import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import {codeMap ,getCode} from '../codes';
import { useSelector, useDispatch } from "react-redux";
import { addItem, deleteItem, setCode, setCodeObj, reset } from "../reducers/cartReducer";
import {updateViaCode} from '../utils/api';
import {useEffect} from 'react';
import {VideoItemsCMS} from './components/VideoItems/videoItemsCMS';

import axios from "axios";
export  const TestingUI = () => {
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const onLoggedIn = () => {
    console.log("onLoggedIn");
  }


  useEffect(() => {
    // Update the document title using the browser API
  }, []);

console.log(state);

  const handleClick = () => {
    const code = document.getElementById("code-aform1-1").value.toUpperCase();
    console.log("handleClick");
    dispatch(setCode(code));
    dispatch(updateViaCode);
    
    // onLoggedIn();
}

const  submitHandler=(e) =>{
  e.preventDefault();
  handleClick();
}

    return(

      <>
      
<VideoItemsCMS/>
      </>


    ) 
    ;
}