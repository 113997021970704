import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    Input,
    Checkbox,
    Textarea ,
    Button,
    Typography,
    Select, Option 
  } from "@material-tailwind/react";
  import { Gallery } from "react-grid-gallery";
  import { Dropbox } from 'dropbox';
  import {
    FileBrowser,
    FileContextMenu,
    FileList,
    FileNavbar,
    FileToolbar,
    ChonkyActions, defineFileAction, setChonkyDefaults 
} from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

setChonkyDefaults({ iconComponent: ChonkyIconFA });

export const FileBrowserScene = () =>{
    const [userName, setUserName] = React.useState("UAI");
    const [directory, setDirectory] = React.useState("");
    const [files, setFiles] = React.useState([]);
    const [DirectoryTree, setDirectoryTree] = React.useState([]);
    const [viewFiles, setViewFiles] = React.useState([]);
    const [optionComponents, setoptionComponents] = React.useState(<Select color="blue">
        <Option value="1">-</Option>
    </Select>);
    const [projectoptionComponents, setprojectoptionComponents] = React.useState(<Select color="blue">
        <Option value="1">-</Option>
        </Select>);
    const dispatch = useDispatch();
    const rootKey = "/Users/"+userName;
    var ACCESS_TOKEN = "sl.Bq39KU1_n1u_BlaJ5IclxOz7Ldbn_WjvqghMZBVc3qVJjQkqAlTI_pJInpQDH0J76QCCx9u6z7acK2fTWdsIIFu9wlMftouKhY_72UQRcfzMD-KHcFOTd8B0MO-1-7jisHGukafgasWDCtPEho-z";



    const convertToViewFiles = (files_) => {
        const filesAr: FileArray =   [
            // `FileArray` is a type exported by Chonky
            null, // Will show loading animation
            { id: 'xWbZ', name: 'Instructions.txt' },
            { id: 'xWbZ', name: 'Tools', isDir: true },
        ];
    
    }
    const myFileActions = [
        ChonkyActions.UploadFiles,
        ChonkyActions.DownloadFiles,
        ChonkyActions.CopyFiles,
        ChonkyActions.DeleteFiles,
        ChonkyActions.OpenFiles,
        ChonkyActions.MouseClickFile,
        ChonkyActions.MoveFiles,
        ChonkyActions.OpenParentFolder,
        ChonkyActions.OpenSelection,
    ];

    const FilesLoaded = (files_, setFileBrowser = true) => {
        console.log(files_);
        const filesMap = files_.map((file) => {
            return {
                id: file.id,
                name: file.name,
                isDir: file['.tag'] === 'folder',
            };
        });
        const skipNames = ["Users", "UAI"];
        const firstFile = files_[0].path_display.split("/");

        let directoryMap = firstFile.map((file) => {
        const cleanedPath = files_[0].path_display.split(file)[0] + file;

            return {
                id: cleanedPath,
                name: file,
            };
        });

        directoryMap = directoryMap.filter((file) => !skipNames.includes(file.name) && files_[0].name !== file.name);

        const files__: FileArray = [
            ...filesMap,
        ];
        if(setFileBrowser ==true){
        setViewFiles(files__);
        setDirectoryTree(directoryMap);
        }
      }

    const listFiles = (path = "" , onFinished = null)=> {
        setDirectory(path);
  
        const dbx = new Dropbox({ accessToken: ACCESS_TOKEN });
        dbx.filesListFolder({path: path})
          .then(function(response) {
            if(onFinished != null){
                setFiles(response.result.entries);
            onFinished(response.result.entries);
            }
          })
          .catch(function(error) {
            console.error(error.error || error);
            setFiles([]);
            
            onFinished([]);

          });

        }

        const DirectoryClicked = (fileInfo) => {
            const matchingFile = files.filter((file) => file.name === fileInfo.name)[0];
            listFiles(matchingFile.path_display, (files_)=>{
                FilesLoaded( files_ , true);
            }
            );
        }


        const OpenParentFolderProcess = (payload) => {
            let ParentFolder = directory.split("/");
            ParentFolder.pop();
              let ParentFolder_ = ParentFolder.join("/");
              listFiles(ParentFolder_, (files_)=>{
                  FilesLoaded( files_ , true);
      }
          );
        }



        const MouseClickFile = (payload) => {
            const fileInfo = payload.file;
            if(fileInfo.isDir){
                DirectoryClicked(fileInfo);
            }
        }

        const OpenFiles = (payload) => {
            const targetFile = payload.targetFile;
            listFiles(targetFile.id, (files_)=>{
                FilesLoaded( files_ , true);
            }
            );
        }



    const handleFileAction = data => {
        const actionID = data.id;
        const actionPayload = data.payload;


        if(actionID === ChonkyActions.MouseClickFile.id){
            MouseClickFile(actionPayload);
        }


        if(actionID === ChonkyActions.OpenFiles.id){
            OpenFiles(actionPayload);
        }

        

        if(actionID === ChonkyActions.OpenParentFolder.id){

           OpenParentFolderProcess(actionPayload);
        }

        


      }
 

const ClientSelected = (e) => {
GetClientProjects(e);
}
const ProjectSelected = (e) => {
    listFiles( e , (files) =>{
    FilesLoaded(files, true);
    }
    );
}
        const GetUserClients = () => {

            listFiles( rootKey , (files) =>{
                setoptionComponents(
<Select color="blue" onChange={ClientSelected}  className="selector-Dropdown" size="sm">
                { files.map((file) => {
                        return <Option key={file.id} value={file.path_display}>{file.name}</Option>
                    })}
                    </Select>
                )
            FilesLoaded(files, false);
            }
            );
        }


        const GetClientProjects = (path) => {

            listFiles( path , (files) =>{
                setprojectoptionComponents(
<Select color="blue" onChange={ProjectSelected}  className="selector-Dropdown" >
                { files.map((file) => {
                        return <Option key={file.id} value={file.path_display}>{file.name}</Option>
                    })}
                    </Select>
                )
            FilesLoaded(files, false);
            }
            );
        }



    useEffect(() => {
        GetUserClients();


    }, []);




    return (
        <div className="CMS-Container" >
        <div className="CMS-Item" >
<div className="selector-Container" >
    <h2 className="selector-Label" >Client</h2>
    {optionComponents}
    </div >

    
<div className="selector-Container" >
    <h2 className="selector-Label" >Project</h2>
    {projectoptionComponents}
    </div >
</div >


<div className="CMS-Item" >

<FileBrowser files={viewFiles}  fileActions={myFileActions} onFileAction={handleFileAction} folderChain={DirectoryTree}>
            <FileNavbar />
            <FileToolbar />
            <FileList />
            <FileContextMenu />
        </FileBrowser>

</div >

</div>
    )

}