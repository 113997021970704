import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import {codeMap ,getCode} from '../codes';
import { useSelector, useDispatch } from "react-redux";
import { addItem, deleteItem, setCode, setCodeObj, reset } from "../reducers/cartReducer";
import {updateViaCode} from '../utils/api';
import {useEffect} from 'react';

import axios from "axios";
export  const CodeLogin = () => {
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const onLoggedIn = () => {
    console.log("onLoggedIn");
  }


  useEffect(() => {
    // Update the document title using the browser API
    dispatch(reset());
  }, []);

console.log(state);

  const handleClick = () => {
    const code = document.getElementById("code-aform1-1").value.toUpperCase();
    console.log("handleClick");
    dispatch(setCode(code));
    dispatch(updateViaCode);
    
    // onLoggedIn();
}

const  submitHandler=(e) =>{
  e.preventDefault();
  handleClick();
}

    return(

      <>
      
<section data-bs-version="5.1" class="form1 cid-tU1alDDVqO Width100" id="aform1-1">

    
    


    
<div class="container transparentBackground">
    <div class="row justify-content-center">
        <div class="col-12 mbr-form" data-form-type="formoid">
<form class="mbr-form form-with-styler" data-form-title="codeEntry"  onSubmit={submitHandler}>
<div class="row">
<div hidden="hidden" data-form-alert="" class="alert alert-success col-12">Code has been entered</div>
<div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12"> Oops...! some problem! </div>
</div>
<div class="dragArea row">
<div class="col-lg-12 col-md-12 col-sm-12">
<h1 class="mbr-section-title align-center mbr-fonts-style colorWhite display-1"> ENTER CODE</h1>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
<p class="mbr-text mbr-fonts-style align-center colorWhite display-5"> Please enter your 5 character XPerience&nbsp;code to access your worlds.</p>
</div>
<div data-for="code" class="col-lg-12 col-md-12 col-sm-12 form-group toppadding">
<input type="text" name="code" id="code" placeholder="*****" data-form-field="code" class="form-control display-7" required="required"  id="code-aform1-1">
</input>
</div>
<div class="col-12 mbr-section-btn">
{/* <button type="submit" class="w-100 w-100 btn btn-primary display-7"  >Login</button> */}
<button type="button" class="w-100 w-100 btn btn-primary display-7 transparentBackground"  onClick={handleClick}>Login</button>
</div>
</div>

</form>
</div>
    </div>
</div>
</section>

      </>


    ) 
    ;
}