import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    Input,
    Checkbox,
    Textarea ,
    Button,
    Typography,
    Select, Option 
  } from "@material-tailwind/react";
  import LoadingIcons from 'react-loading-icons'
  import {uuidv4} from '../../../utils/api';
  import {vitsVoices} from "../constants/models";
import axios from 'axios';
import { faMars, faVenus  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const Vits = ({NewRecieved}) =>{

    const [models, setModels] = React.useState( [] );
  
    const [model, setModel] = React.useState("Lady");
    const [prompt, setPrompt] = React.useState("");
    const [steps, setSteps] = React.useState(20);
    const [mode, setMode] = React.useState("input");
    const Divider = () => {
      return (
          <hr
              className="Divider"
          ></hr>
      );
  };
    const RunProcess = async () => {
      setMode("loading");
      const matched = vitsVoices.speakers.filter(item => item.Name == model)[0];

      let appended = "";
      if(model.includes( "wavymulder/Analog-Diffusion")){
        appended = "analog style ";
      }
    const outReq = {
        "text": appended+ prompt,
        "speaker": parseFloat(matched.Number),
    }

    localStorage.setItem("VITSReq", JSON.stringify(outReq));
    const resp = await axios.post("https://bravomedianyc--texttovoice-vits-flask-app.modal.run/vits", outReq,    {
        'Access-Control-Allow-Origin': '*'
    });
    // const resspData = JSON.parse( resp.data.body);
    const outResponse = {
        "media": resp.data,
        "prompt": prompt,
        "steps": 15,
        "model": "VITS",
        "speaker": model,
        "gender": matched.Gender,
        "date": new Date().toLocaleString(),
        "id": uuidv4()
    }
    NewRecieved(outResponse);
    setMode("input");

    }

    useEffect(() => {

      const req = localStorage.getItem("VITSReq");
      const items = vitsVoices.speakers.map(item =>{
        const gender = item.Gender == "Male" ?   <FontAwesomeIcon icon={faMars} className=" text-xs mr-3 text-blue-800 cursor-pointer" /> : <FontAwesomeIcon icon={faVenus} className=" text-xs mr-3 text-pink-400 cursor-pointer" /> 
                  
        return <Option key={item.Number} value={item.Name}>{gender} {item.Name}</Option>
      });
      setModels(items);
      setModel(vitsVoices.speakers[0].Name);
      // console.log(vitsVoices.speakers.map(item =>{
      //   <Option value={item.Number}>{item.Name}</Option>
      // }));
      if(req != null){
        const reqObj = JSON.parse(req);
        setPrompt(reqObj.prompt);
        setSteps(reqObj.steps);
        setModel(reqObj.model);
      }
    }
    ,[]);

    return (
         <Card  className="w-auto" color="white" shadow={true} >
      <div className="p-10">
        <Typography color="black" className="mt-1 text-sm font-bold">
          VITS
        </Typography>
        <Typography color="gray" className="mt-1 text-xs">
          A multi speaker model trained off of British speakers.
        </Typography>
        
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-1 flex flex-col gap-6">

          <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Voice
            </Typography>

          <Select variant="outlined" id="showSelect" label="Model" onChange={(e) => {setModel(e)}}>
                  {models}
                </Select>

            
                <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Text
            </Typography>
            <Textarea label="Text" 
            value={prompt}
              onChange={(e) => setPrompt(e.target.value)}  />
          
                {/* <Typography variant="h6" color="blue-gray" className="-mb-3 headerInput">
              Steps
            </Typography>
            <Input
              size="lg"
              placeholder="42"
              containerProps={{ className: "min-w-[72px]" }}

              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}    
              type="number"
              value={steps}
              onChange={(e) => setSteps(e.target.value)}
            /> */}
          <Divider/>

  


          </div>
          {mode == "loading"?  <Button className="mt-6" fullWidth disabled={true} >
            Loading
          </Button> :  <Button className="mt-6" fullWidth onClick={RunProcess}>
            Generate
          </Button>}
        </form>
</div>

</Card>
    )


}