 export class ImageRequestBase
    {
        constructor() {
        this.mode = "all";
        this.submode = "file";
        this.model = "prompthero/openjourney-v4";
        this.subModel = "";
        this.onnxModel = "";
        this.modelType = "vit_h";
        this.inpaintModel = "stabilityai/stable-diffusion-2-inpainting";
        this.input = "";
        this.output = "";
        this.name = "";
        this.prompt = "";
        this.neg_prompt = "";
        this.customSDBinWeights = "";
        this.textualInversionWeights = "";
        this.loraWeights = "";
        this.refinerRepo = "";
        this.vaeRepo = "";
        this.schedueler = "EulerAncestralDiscreteScheduler";
        this.device = "cuda";
        this.pythonPath = "";
        this.tempPath = "";
        this.paramsData = "";
        this.steps = 15;
        this.width = 512;
        this.height = 512;
        this.highThreshold = 100;
        this.lowThreshold = 150;
        this.guidance_scale = 7.5;
        this.controlnet_scale = 1.2;
        this.image_guidance_scale = 0.8;
        this.rotationx = 0;
        this.rotationy = 0;
        this.rotationz = 0;
        this.scalex = 1;
        this.scaley = 1;
        this.scalez = 1;
        this.seed = 42;
        this.imagesToGenerate = 1;
        this.x = 0;
        this.y = 0;
        this.fps = 12;
        this.high_noise_frac = 0.8;
        this.maskIndex = 0;
        this.zip = false;
        this.isXL = false;
        this.debugmode = false;
        this.mediaPaths = [];
        this.seeds = [];
        this.controlnet_conditioning_scales = [];

        }

        json(){
            return JSON.stringify(this);
        }
    }