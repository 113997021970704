
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {verifyAPIKey} from './auth';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import { query } from "firebase/firestore";
import { where } from "firebase/firestore";
import { useState, useEffect } from 'react'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCR51YVFknCU8bFfDDtWpB1dkr5z-NhmEI",
  authDomain: "uai-backend.firebaseapp.com",
  projectId: "uai-backend",
  storageBucket: "uai-backend.appspot.com",
  messagingSenderId: "160410010735",
  appId: "1:160410010735:web:0499f3035607f92dd3b99a"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);











export default app;




export function useAuth() {
    const [authState, setAuthState] = useState({
        isSignedIn: false,
        pending: true,
        user: null,
    })

    useEffect(() => {
        const unregisterAuthObserver = getAuth(app).onAuthStateChanged(user =>
        setAuthState({ user, pending: false, isSignedIn: !!user })
        )
        return () => unregisterAuthObserver()
    }, [])
    return { auth, ...authState }
}

export function useUser() {
    const [user, setUser] = useState(null);
    useEffect(() => {
        const key_ = localStorage.getItem('user');
        if(key_){
            setUser(key_);
        }
    }, [])
    return {user} ;
}

export function useHideNav() {
    const [hideNav, setHideNav] = useState(null);
    useEffect(() => {
        const key_ = localStorage.getItem('hideNav');
        if(key_){
            if(key_ === "true"){
                setHideNav(true);
            }else{
                setHideNav(false);
            }

        }
    }, [])
    return {hideNav} ;
}

export function useAPIKey() {
    const [apiKey, setAPIKey] = useState(null);
    const [verified, setVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const key_ = localStorage.getItem('APIKey');

        const verifyKey = async () => {
            const verified_ = await verifyAPIKey(key_);
            setVerified(verified_);
            setIsLoading(false);
        }
        if(key_){
            setAPIKey(key_);
            verifyKey();
        }
    }, [])
    return {apiKey , verified, isLoading} ;
}


export const auth = getAuth(app);





export const createNewUser = async ( email, provider , phone = "5551234567", picture= "https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/UAIUserBig.jpg") => {
    const start = Date.now();
    const startDateTime = new Date(start).toISOString();
    const newUser = {
        "id": await getNextUserID(),
        "username": email.split("@")[0],
        "email": email,
        "provider": provider,
        "confirmed": false,
        "blocked": false,
        "role": {
            "id": 1,
            "name": "Authenticated",
            "description": "Default role given to authenticated user.",
            "type": "authenticated"
        },
        "created_at": startDateTime,
        "updated_at": startDateTime,
        "usertype": "Trial",
        "industry": "Stuff",
        "paymenttype": "trial",
        "avatar": picture,
        "phone": phone,
        "stripeId": "NA",
        "sub": "NA",
        "allowNSFW": false,
        "tokens": [],
        "apiusage": [],
        "licenses": [],
        "triggeredwarnings": [],
        "argeements": [],
        "playlists": [],
        "fav_playlists": [],
        "upvoted_playlists": [],
        "upvoted_media": [],
        "fav_media": [],
        "issues": [],
        "organizations": []
    }
    const db = await getFirestore(app);
    const collection_ = collection(db, 'users');
    collection_.doc(newUser.id.toString()).set(newUser);
    return newUser;
}




export const getNextUserID = async () => {

    const db = await getFirestore(app);
    const collection_ = collection(db, 'users');
    const items = collection_;
    const querySnapshot = await getDocs(items);
    const count = querySnapshot.size;
    return count + 1;

}

export const getProducts = async (showInShop = false) => {

    const db = await getFirestore(app);
    const collection_ = collection(db, 'dlcs');
    const querySnapshot = await getDocs(collection_);
    const items = [];
    for( var i = 0; i < querySnapshot.docs.length; i++){
        const doc = querySnapshot.docs[i];
        const item = doc.data();
        if(showInShop){
            if(item.showInShop){
                items.push(item);
            }
        }else{  
            items.push(item);
        }
        
    }
    return items;
}

export const getCollection = async (collectionName) => {
    
        const db = await getFirestore(app);
        const collection_ = collection(db, collectionName);
        const querySnapshot = await getDocs(collection_);
        const items = [];
        for( var i = 0; i < querySnapshot.docs.length; i++){
            const doc = querySnapshot.docs[i];
            const item = doc.data();
            items.push(item);
        }
        return items;
    }

export const getxperienceByCode = async (code) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, 'xperiences');
    const items = collection_;
    const querySnapshot = await getDocs(items);
    let match = {};
    for( var i = 0; i < querySnapshot.docs.length; i++){
        const doc = querySnapshot.docs[i];
        const item = doc.data();
        if (item.code === code) {
            match = item;
            break;
        }
    }
    return match;

}



export const getUser = async (user) => {

    const db = await getFirestore(app);
    const collection_ = collection(db, 'users');
    const items = collection_;
    const querySnapshot = await getDocs(items);
    const count = querySnapshot.size;
    console.log(count);
    let matched = false;
    let match = {};
    for( var i = 0; i < querySnapshot.docs.length; i++){
        const doc = querySnapshot.docs[i];
        const item = doc.data();
        if (item.email === user.email) {
            match = item;
            matched = true;
            break;
        }
    }
    if (matched) {
        return match;
    }
    else{
        const newUser = await createNewUser(user.email, "google",user.providerData[0].phoneNumber, user.photoURL);
        return newUser;
    }



}


