import {getExperienceByCode} from './utils/api';

export const codeMap ={

    "items":[
{

    "code":"GARC!",
    "name": " Pix2Pix" ,
    "description":"Augment an image with a pix2pix model",
    "experiences":[
        {
            "appType":"Photobooth",
            "title":"Camera Mode",
            "description":"Take photos for the experience.",
            "thumbnail":"assets/images/image20.jpg",
            "tutorials":[
                ""
            ],
            "url":"camera?code=BOXES&experience=Camera_Mode&cameramode=environment",
            "help":"Photobooth to augment images to another image using AI.",
            "params":{
                "cameramode":"environment",
                "experience":"Camera_Mode",
                "subMode":"AIPix2Pix",
                "endpoint":"https://vltmedia--aiphotobooth-guiseepearcimboldo-01-pix2pix.modal.run/",
                "presets":{
                    "styles":[
                        {
                            "name": "Guiseppe Arcimboldo",
                            "style": "fruits",
                            "prompt": "make it a AG153ppi painting. make it in the style of Guiseppe Arcimboldo. Tasteful Contrast. rennaisance painting.  Make the face visible. put big fruit and vegetables on the face.",
                            "negprompt": "small fruits and vegetables. low quality. blurry. high contrast. deep fried. not a painting. no visible face. no human face. blury fruits and vegetables.",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/Guiseppe.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Salvador Dalí",
                            "style": "normal",
                            "prompt": "Make it A painting in the style of salvador dalí. amazing painting",
                            "negprompt": "Not a painting in the style of salvador dalí. A painting of the person salvador dalí",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/SalvadorDali.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Frida Kahlo",
                            "style": "normal",
                            "prompt": "Make it A painting in the style of Frida Kahlo.",
                            "negprompt": "Not a painting in the style of Frida Kahlo. A painting of the person Frida Kahlo",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/FridaKahlo.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Roy Lichtenstein",
                            "style": "normal",
                            "prompt": "Make it art in the style of Roy Lichtenstein . artwork",
                            "negprompt": "Not a in the style of Roy Lichtenstein . A painting of the person Roy Lichtenstein ",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/RoLichtenstein.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Piet Mondrian",
                            "style": "normal",
                            "prompt": "A painting of a person in the style of Piet Mondrian",
                            "negprompt": "Not a painting in the style of Piet Mondrian. A painting of the person Piet Mondrian.",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/PietMondrian.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "El Greco",
                            "style": "normal",
                            "prompt": "A painting in the style of El Greco",
                            "negprompt": "Not a painting in the style of El Greco. A painting of the person El Greco",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/ElGreco.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Banksy",
                            "style": "normal",
                            "prompt": "A painting in the style of Banksy",
                            "negprompt": "Not a painting in the style of Banksy.",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/banksy.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Jean-Michel Basquiat",
                            "style": "normal",
                            "prompt": "A painting in the style of jean-michel basquiat",
                            "negprompt": "Not a painting in the style of jean-michel basquiat. A painting of the person jean-michel basquiat",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/jean-michelbasquiat.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Yayoi Kusama",
                            "style": "normal",
                            "prompt": "A painting of a person in the style of yayoi Kusama. dots. ",
                            "negprompt": "Not a painting in the style of yayoi Kusama. A painting of the person yayoi Kusama",
                            "description": "Art by Yayoi Kusama",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/yayoiKusama.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Art Deco",
                            "style": "normal",
                            "prompt": "Make it an art deco poster. high quality.",
                            "negprompt": "Not art deco. low quality. blurry",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/ArtDeco.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Todd Macfarlane",
                            "style": "normal",
                            "prompt": "Make it a comic panel in the style of todd macfarlane. high quality. amazing comic.",
                            "negprompt": "Not a comic book style. a photo of todd macfarlane",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/toddmacfarlane.jpg",
                            "model": "prompthero/openjourney"
                        },
                        {
                            "name": "Pablo Picasso",
                            "style": "normal",
                            "prompt": "A painting in the style of Picasso",
                            "negprompt": "Not a painting in the style of Picasso. A painting of the person Pablo Picasso.",
                            "description": "Art by Pablo Picasso",
                            "thumbnail": "https://installers.uai.software/apps/aiPhotobooth/Styles/toddmacfarlane.jpg",
                            "model": "prompthero/openjourney"
                        }
                ]
            },
            }
        }
    ] 

},{

    "code":"BOXES",
    "name": "Boxes" ,
    "description":"Take pictures for the boxes show", 
    "experiences":[
        {
            "appType":"Photobooth",
            "title":"Camera Mode",
            "description":"Take photos for the experience.",
            "thumbnail":"assets/images/image20.jpg",
            "url":"camera?code=BOXES&experience=Camera_Mode&cameramode=environment",
            "params":{
                "cameramode":"environment",
                "experience":"Camera_Mode",
                "subMode":"AIPix2Pix",
            }
        },
        {
            "appType":"Photobooth",
            "title":"Image Review",
            "description":"Review and process images taken during this XPerience.",
            "thumbnail":"assets/images/image20.jpg",
            "url":"review?code=BOXES&experience=Camera_Mode"
        },
        {
            "appType":"Show App",
            "title":"Boxes Show Controller",
            "description":"Run the show with buttons and queues.",
            "thumbnail":"assets/images/image20.jpg",
            "url":"showeApp?code=BOXES&experience=Controller_Mode"
        }
    ] 

}
    ]
}

export const getCode = async (code)=>{
    return await getExperienceByCode(code);
    // const items = codeMap.items;
    // for (let i = 0; i < items.length; i++) {
    //     if(items[i].code == code){
    //         return items[i];
    //     }
    // }
    // return null;
}

