
// import logo from './logo.svg';
// import {WebcamCapture} from './cam/WebcamCapture';
// import PhotoboothApp from './ui/photoboothApp';
// import * as ReactDOM from "react-dom/client";
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
import './App.css';
import './uai.css';
import { Provider } from "react-redux";
import store from "./store";

// import {Routes} from './routes'

// function App() {
//   return (
//     <div className="App">
// {/*       
//       <header className="App-header">
//         <PhotoboothApp/>
//       </header> */}


//     </div>
//   );
// }


// export default ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>
// );


// export default App;


import * as React from 'react';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import {useEffect} from 'react';

import { TestingUI} from "./ui/testingUI";
import {ShowAppHome} from './ui/components/ShowApp/ShowAppHome';
import { CodeLogin} from "./ui/codeSignin";
import  CameraPerson from "./apps/photobooths/CameraPerson/cameraPerson";
import  AIPix2Pix from "./apps/photobooths/AIPix2Pix/aiPix2Pix";
import  ImageReviewPage from "./ui/imageReview";
import  {FaceTalker} from "./apps/face/faceTalker";
import  TopNav from "./ui/topNav";
import  {TopNavNotLoggedIn , TopNavSelector} from "./ui/topNav";
import {CameraSelector} from './apps/photobooths/components/cameraSelector';
import {ImageGenerationScene} from './apps/imageGen';
import {TTSScene} from './apps/tts';
import {FileBrowserScene} from './apps/bravocms/pages/FileBrowser';

export default function App() {

  const [navBar, setNavBar] = React.useState( <TopNavNotLoggedIn/>);
  const [homeComponent, sethomeComponent] = React.useState(< Home onLoggedIn={()=>{window.location="camera"}} />);
  const [code, setCode] = React.useState();
  // useEffect(() => {
  //   const code_ = state.code;
  //   // const code_ = localStorage.getItem("code");
  //   if(code_ != null ){
  //     setNavBar(<TopNav/>);
  //     setCode(code_);
  //     // sethomeComponent(<Home onLoggedIn={()=>{window.location="camera"}} /> );
  //   }
  //   // if(width < 891){
  //   //   setScrollClass("scrollx" );
  //   // }
  // }, []);
  return (
    <Provider store={store}>
    <div className="App">
      <header className="App-header">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={      <TopNavSelector/>}>
          <Route index element={homeComponent} />
          <Route path="codeLogin" element={ <CodeLogin onLoggedIn={()=>{window.location="camera"}} />} />
          <Route path="camera" element={<CameraSelector  />} />
          <Route path="review" element={<ImageReviewPage  />} />
          <Route path="showApp" element={<ShowAppHome  />} />
          <Route path="faceTalker" element={<FaceTalker  />} />
          <Route path="test" element={<TestingUI  />} />
          <Route path="imageGen" element={<ImageGenerationScene  />} />
          <Route path="tts" element={<TTSScene  />} />
          <Route path="bravocms" element={<FileBrowserScene  />} />
          <Route path="logout" element={homeComponent} />
        </Route>
      </Routes>
    </BrowserRouter>
    </header>
    </div>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);